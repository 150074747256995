import * as React from 'react'

import { AccountBalance } from '@mui/icons-material'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { BANKS } from '../../services'

import type { BankAccount, BankConfig } from '../../queries'

type BankAccountDisplayProps = {
  bankConfig?: BankConfig
  bankAccount: BankAccount | null
}

export const BankAccountDisplay = ({
  bankConfig,
  bankAccount,
}: BankAccountDisplayProps) => (
  <React.Fragment>
    <ListItem>
      <ListItemIcon>
        <AccountBalance />
      </ListItemIcon>
      <ListItemText
        primary={BANKS[bankAccount?.bankCode || ''].label}
        secondary={(
          <React.Fragment>
            N. cuenta: {bankAccount?.number}
            <br />
            N. documento: {bankConfig?.userLegalDocumentNumber}
            <br />
            Nombre: {bankConfig?.userLegalName}
          </React.Fragment>
        )}
      />
    </ListItem>
  </React.Fragment>
)
