import * as React from 'react'

import { OpenInNew } from '@mui/icons-material'
import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'

import { Image } from '../components'
import { POI_CONTENT_TYPE_LABELS, POI_DOCUMENT_TYPE_LABELS } from '../queries'

import type { LegalIdentityProofView } from '../queries'

type DocumentDisplayProps = Pick<LegalIdentityProofView, 'contentType' | 'documentType' | 'storageUrl'>

export const DocumentDisplay = ({
  contentType,
  documentType,
  storageUrl,
}: DocumentDisplayProps) => {
  if (!storageUrl) {
    return (
      <ListItemText
        primary='No se ha subido una imagen.'
        secondary='Es posible que se haya producido un error mientras se subía la imagen.'
      />
    )
  } else if (['image/heic', 'application/pdf'].includes(contentType)) {
    return (
      <ListItemButton
        disableGutters
        component='a'
        target='_blank'
        href={storageUrl}
        sx={{ pl: 2 }}
      >
        <ListItemText
          primary='Abrir documento en otra pestaña'
          secondary={`Tipo de archivo: ${POI_CONTENT_TYPE_LABELS[contentType]}`}
        />
        <ListItemAvatar>
          <Avatar>
            <OpenInNew />
          </Avatar>
        </ListItemAvatar>
      </ListItemButton>
    )
  }

  return (
    <a
      style={{ width: '100%' }}
      href={storageUrl}
      target='_blank'
      rel='noreferrer'
    >
      <Image
        src={storageUrl}
        alt={POI_DOCUMENT_TYPE_LABELS[documentType]}
        fit='contain'
        duration={300}
        wrapperStyle={{ height: 'min(20vh, 250px)' }}
      />
    </a>
  )
}
