import { gql } from '@apollo/client'

export type User = {
  id: string
  email: string
  nickName: string
}

export const BULK_PURCHASE_TRANSFERABLE_USERS_FIELDS = gql`
  fragment BulkPurchaseTransferableUsersFields on User {
    id
    email
    nickName
  }
`

export type BulkPurchaseTransferableUsersVars = {
  filter?: string
}

export type BulkPurchaseTransferableUsersData = {
  bulkPurchaseTransferableUsers: User[]
}

export const BULK_PURCHASE_TRANSFERABLE_USERS_QUERY = gql`
  ${BULK_PURCHASE_TRANSFERABLE_USERS_FIELDS}
  query BulkPurchaseTransferableUsers($filter: String) {
    bulkPurchaseTransferableUsers(filter: $filter) {
      ...BulkPurchaseTransferableUsersFields
    }
  }
`

export type TransferBulkPurchaseVars = {
  amount: number
  bulkPurchaseId: string
  receiverId: string
}

export type TransferBulkPurchaseData = {
  transferBulkPurchase: string
}

export const TRANSFER_BULK_PURCHASE_MUTATION = gql`
  mutation TransferBulkPurchase(
    $amount: Float!
    $bulkPurchaseId: ID!
    $receiverId: ID!
  ) {
    transferBulkPurchase(
      input: {
        amount: $amount
        bulkPurchaseId: $bulkPurchaseId
        receiverId: $receiverId
      }
    )
  }
`
