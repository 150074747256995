import * as React from 'react'

import { Stack } from '@mui/material'

import { AddressStep } from './address_step'
import { AmountStep } from './amount_step'
import { AuthCodeStep } from './auth_code_step'
import { ConfirmationStep } from './confirmation_step'

import type { MarketAsset, TickerPrice, UserOperation } from '../../queries'

export type WithdrawBlockchainFormProps = {
  marketAssets: MarketAsset[]
  tickerPrices: TickerPrice[]
  operations: UserOperation[]
}

export const WithdrawBlockchainForm = ({
  marketAssets,
  tickerPrices,
  operations,
}: WithdrawBlockchainFormProps) => {
  const [step, setStep] = React.useState(1)
  const [address, setAddress] = React.useState<string>('')
  const [amount, setAmount] = React.useState<number>(0)
  const [blockchain, setBlockchain] = React.useState<string>('')
  const [marketAsset, setMarketAsset] = React.useState<MarketAsset | null>(null)

  const handleBack = () => {
    setStep(Math.max(step - 1, 1))
  }

  const handleNext = () => {
    setStep((step) => step + 1)
  }

  return (
    <Stack
      margin='auto'
      height='100%'
      width='100%'
      maxWidth='420px'
      spacing={1}
      pb={1}
    >
      {(() => {
        switch (step) {
        case 1: return (
          <AmountStep
            marketAssets={marketAssets}
            tickerPrices={tickerPrices}
            operations={operations}
            marketAsset={marketAsset}
            amount={amount}
            handleNext={(marketAsset: MarketAsset, amount: number) => {
              setMarketAsset(marketAsset)
              setAmount(amount)
              handleNext()
            }}
          />
        )
        case 2: return (
          <AddressStep
            marketAsset={marketAsset}
            blockchain={blockchain}
            address={address}
            handleBack={handleBack}
            handleNext={(blockchain: string, address: string) => {
              setBlockchain(blockchain)
              setAddress(address)
              handleNext()
            }}
          />
        )
        case 3: return (
          <AuthCodeStep
            marketAsset={marketAsset}
            blockchain={blockchain}
            address={address}
            amount={amount}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )
        case 4: return (
          <ConfirmationStep
            marketAsset={marketAsset}
            blockchain={blockchain}
            address={address}
            amount={amount}
          />
        )
        }
      })()}
    </Stack>
  )
}
