import * as React from 'react'

import { List, ListItem, ListItemText } from '@mui/material'

import { rangeLabels } from './range_buttons'
import { AppContainer, Currency, PercentVariation } from '../components'

import type { AssetTimeframeData } from '../services'

export type PriceStatisticsProps = {
  currency?: string
  range: string
  timeframeData: AssetTimeframeData
}

export const PriceStatistics = ({
  currency = 'USD',
  range,
  timeframeData,
}: PriceStatisticsProps) => (
  <AppContainer
    md={6}
    sx={{ height: '100%' }}
  >
    <List
      aria-label='Estadísticas de Precio'
      sx={{ p: 1 }}
    >
      <ListItem divider>
        <ListItemText
          primaryTypographyProps={{
            variant: 'h5',
          }}
          primary='Estadísticas de Precio'
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Precio actual'
          secondary={`Variación ${rangeLabels[range].long.toLowerCase()}`}
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(
            <Currency
              currency={currency}
              value={timeframeData.lastPrice}
            />
          )}
          secondary={(
            <PercentVariation
              value={timeframeData.variation}
            />
          )}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Precio máximo'
          secondary={rangeLabels[range].long}
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(
            <Currency
              currency={currency}
              value={timeframeData.ath}
            />
          )}
        />
      </ListItem>
      <ListItem divider={timeframeData.volume !== 0}>
        <ListItemText
          primary='Precio mínimo'
          secondary={rangeLabels[range].long}
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(
            <Currency
              currency={currency}
              value={timeframeData.atl}
            />
          )}
        />
      </ListItem>
      {timeframeData.volume !== 0 && (
        <ListItem>
          <ListItemText
            primary='Volumen de operaciones'
            secondary={rangeLabels[range].long}
          />
          <ListItemText
            primaryTypographyProps={{
              textAlign: 'right',
            }}
            primary={(
              <Currency
                currency={timeframeData.symbol}
                value={timeframeData.volume}
                digits={2}
              />
            )}
          />
        </ListItem>
      )}
    </List>
  </AppContainer>
)
