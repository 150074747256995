import { getAssetTickerPrices, getSymbol } from '../services'

import type { PriceGraphPoint } from '../components'
import type { TickerPrice } from '../queries'

const ONE_MONTH_SAMPLES = 30 * 24
const ONE_DAY_IN_SECONDS = 60 * 60 * 24

export type AssetTimeframePrice = PriceGraphPoint & {
 quoteValue: number
}

export type AssetTimeframeData = {
  symbol: string
  volume: number
  variation: number
  ath: number
  atl: number
  openPrice: number
  openTimestamp: number
  lastPrice: number
  lastTimestamp: number
  prices: AssetTimeframePrice[]
}

export const getTimeframeData = (
  tickerPrices: TickerPrice[],
  symbol: string,
  quoteSymbol: string,
): AssetTimeframeData => {
  const quotePrices = getAssetTickerPrices(tickerPrices, quoteSymbol)
  const assetPrices = getAssetTickerPrices(tickerPrices, getSymbol(symbol))
  const timeframeData: AssetTimeframeData = {
    symbol: getSymbol(symbol),
    volume: 0,
    variation: 0,
    ath: 0,
    atl: 0,
    openPrice: 0,
    openTimestamp: 0,
    lastPrice: 0,
    lastTimestamp: 0,
    prices: [],
  }

  const isQuote = getSymbol(symbol) === quoteSymbol
  const isQuoteUSDT = quoteSymbol === 'USDT'

  onCommonOpenTimestamp(quotePrices, assetPrices, (quotePrice, assetPrice) => {
    timeframeData.volume += isQuote ? quotePrice.volume : assetPrice.volume

    const quoteValue = isQuote ? 1 : assetPrice.openPrice

    timeframeData.prices.push({
      timestamp: assetPrice.openTimestamp,
      quoteValue: quoteValue,
      value: isQuoteUSDT ? assetPrice.openPrice : quotePrice.openPrice * quoteValue,
    })
  })

  const priceCount = timeframeData.prices.length

  if (priceCount > 0) {
    timeframeData.openPrice = timeframeData.prices[0].value
    timeframeData.openTimestamp = timeframeData.prices[0].timestamp
    timeframeData.lastPrice = timeframeData.prices[priceCount - 1].value
    timeframeData.lastTimestamp = timeframeData.prices[priceCount - 1].timestamp
    timeframeData.variation =
      100.0 * (timeframeData.lastPrice - timeframeData.openPrice) / timeframeData.openPrice
    const priceValues = timeframeData.prices.map((price) => price.value)
    timeframeData.ath = Math.max(...priceValues)
    timeframeData.atl = Math.min(...priceValues)
  }

  if (timeframeData.prices.length > ONE_MONTH_SAMPLES) {
    timeframeData.prices = timeframeData.prices.filter(
      (price) => (timeframeData.lastTimestamp - price.timestamp) % ONE_DAY_IN_SECONDS === 0,
    )
  }

  return timeframeData
}

export const onCommonOpenTimestamp = (
  quotePrices: TickerPrice[],
  assetPrices: TickerPrice[],
  reducer: (quotePrice: TickerPrice, assetPrice: TickerPrice) => void,
) => {
  let quoteIndex = 0, assetIndex = 0

  while (quoteIndex < quotePrices.length && assetIndex < assetPrices.length) {
    const quotePrice = quotePrices[quoteIndex]
    const assetPrice = assetPrices[assetIndex]

    const quoteTimestamp = quotePrice.openTimestamp
    const assetTimestamp = assetPrice.openTimestamp

    if (quoteTimestamp < assetTimestamp) {
      quoteIndex++
      continue
    }
    if (quoteTimestamp > assetTimestamp) {
      assetIndex++
      continue
    }

    reducer(quotePrice, assetPrice)
    quoteIndex++
    assetIndex++
  }
}
