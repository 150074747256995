import * as React from 'react'

import { Stack } from '@mui/material'

import { ConfirmationStep } from './confirmation_step'
import { QuoteConfirmationStep } from './quote_confirmation_step'
import { QuoteVarsStep } from './quote_vars_step'

import type { TotalAmounts } from './types'
import type { CreateSwapQuoteVars, MarketAsset, Swap, SwapConfig } from '../../queries'

type SwapFormProps = {
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
  swapConfig: SwapConfig
}

export const SwapForm = ({
  marketAssets,
  totalAmounts,
  swapConfig,
}: SwapFormProps) => {
  const [step, setStep] = React.useState(1)
  const [createSwapQuoteVars, setCreateSwapQuoteVars] = React.useState<CreateSwapQuoteVars>()
  const [swap, setSwap] = React.useState<Swap>()

  const handleBack = async () => {
    setStep(Math.max(step - 1, 1))
  }

  return (
    <Stack
      pb={1}
      margin='auto'
      height='100%'
      width='100%'
      maxWidth='420px'
    >
      {(() => {
        switch (step) {
        case 1: return (
          <QuoteVarsStep
            marketAssets={marketAssets}
            totalAmounts={totalAmounts}
            swapConfig={swapConfig}
            createSwapQuoteVars={createSwapQuoteVars}
            handleNext={(vars: CreateSwapQuoteVars) => {
              setCreateSwapQuoteVars(vars)
              setStep(2)
            }}
          />
        )
        case 2: return (
          <QuoteConfirmationStep
            createSwapQuoteVars={createSwapQuoteVars}
            handleBack={handleBack}
            handleNext={(swap?: Swap) => {
              setSwap(swap)
              setStep(3)
            }}
          />
        )
        case 3: return (
          <ConfirmationStep
            swap={swap}
            handleRestart={() => setStep(1)}
          />
        )
        }
      })()}
    </Stack>
  )
}
