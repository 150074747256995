import * as React from 'react'

import { AccountBalance, AddShoppingCart, Close, Error } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { Link } from '@reach/router'

import {
  ButtonContainer,
  ButtonsContainer,
  CopyButton,
  Loading,
  MonospaceText,
  SupportMailLink,
} from '../../components'
import { AppContext } from '../../contexts'
import { loadSimplex, numberFormatter } from '../../services'

import type { CreatePurchaseQuoteVars, Payment, PurchaseConfig } from '../../queries'

const MAX_HEIGHT = 'min(600px, calc(100vh - 24px))'
const MAX_WIDTH = 'min(500px, calc(100vw - 24px))'

type ModalStatus = 'OPEN' | 'SUCCESS' | 'FAILURE' | 'CANCELLED'

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: theme.shadows[12],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}))

type FlowRestartFnType = () => void

type FlowEndMessageProps = {
  title: string
  subtitle: string
  handleRestart: FlowRestartFnType
  children?: React.ReactNode
}

const FlowEndMessage = ({
  title,
  subtitle,
  handleRestart,
  children,
}: FlowEndMessageProps) => (
  <Stack
    spacing={1}
    alignItems='center'
  >
    <Typography
      variant='h6'
      component='span'
      textAlign='center'
    >
      {title}
    </Typography>
    <Typography
      variant='subtitle1'
      component='p'
      textAlign='center'
    >
      {subtitle}
    </Typography>
    {children}
    <ButtonsContainer sx={{ alignItems: 'flex-end', pt: 1 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleRestart}
          startIcon={<AddShoppingCart />}
          variant='outlined'
          sx={{ textAlign: 'center' }}
        >
          Volver a comprar
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../portfolio'
          startIcon={<AccountBalance />}
          variant='contained'
          sx={{ textAlign: 'center' }}
        >
          Ir a Portafolio
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Stack>
)

const CancelledMessage = ({
  handleRestart,
}: { handleRestart: FlowRestartFnType }) => (
  <FlowEndMessage
    title='Compra cancelada'
    subtitle={(
      'Si tuviste problemas para realizar la compra, escríbenos a nuestro'
      + ' correo de soporte:'
    )}
    handleRestart={handleRestart}
  >
    <SupportMailLink variant='text' />
  </FlowEndMessage>
)

const SuccessMessage = ({
  handleRestart,
}: { handleRestart: FlowRestartFnType }) => (
  <FlowEndMessage
    title='Dólares digitales en camino'
    subtitle='Tu balance se actualizará apenas confirmemos el pago con nuestro proveedor.'
    handleRestart={handleRestart}
  />
)

const FailureMessage = ({
  handleRestart,
}: { handleRestart: FlowRestartFnType }) => (
  <FlowEndMessage
    title='Ocurrió un error'
    subtitle={(
      'Si tuviste problemas para realizar la compra, escríbenos a nuestro'
      + ' correo de soporte:'
    )}
    handleRestart={handleRestart}
  >
    <SupportMailLink variant='text' />
  </FlowEndMessage>
)

type SimplexWidgetProps = {
  paymentId?: string
  setModalStatus: (status: ModalStatus) => void
}

const SimplexWidget = ({
  paymentId,
  setModalStatus,
}: SimplexWidgetProps) => {
  const { simplexPublicKey, simplexScriptSrc } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (typeof paymentId === 'undefined') {
      return
    }

    loadSimplex({
      publicKey: simplexPublicKey,
      scriptSrc: simplexScriptSrc,
      callback: () => {
        Simplex.load({ payment_id: paymentId }, () => {
          setLoading(false)
        })
        Simplex.subscribe('onlineFlowFinished', (event) => {
          setModalStatus(event.payload.result === 'success' ? 'SUCCESS' : 'FAILURE')
        })
      },
    })
  }, [paymentId, setModalStatus, simplexPublicKey, simplexScriptSrc])

  return (
    <form
      id='simplex-form'
      style={{
        height: MAX_HEIGHT,
        width: MAX_WIDTH,
      }}
    >
      <div
        id='checkout-element'
        style={{ height: '100%' }}
      >
        {loading && <Loading height='100%' />}
      </div>
    </form>
  )
}

type KoyweBankTransferContentProps = {
  purchaseConfig: PurchaseConfig
  createPurchaseQuoteVars?: CreatePurchaseQuoteVars
  paymentMethodDetails: string
  handleSuccess: () => void
}

const KoyweBankTransferContent = ({
  purchaseConfig,
  createPurchaseQuoteVars,
  paymentMethodDetails,
  handleSuccess,
}: KoyweBankTransferContentProps) => (
  <React.Fragment>
    <Typography>
      Para finalizar esta compra, deberás transferir
      {' '}
      ${numberFormatter(0).format(createPurchaseQuoteVars?.fiatAmount || 0)}
      {' '}
      {purchaseConfig.userFiatCurrency} a la cuenta de nuestro proveedor:
    </Typography>
    <MonospaceText
      width='100%'
      textAlign='center'
    >
      {paymentMethodDetails}
    </MonospaceText>
    <CopyButton
      textToCopy={paymentMethodDetails}
      buttonText='Copiar datos'
      buttonPressedText='¡Datos copiados!'
    />
    <Button
      onClick={handleSuccess}
      variant='outlined'
      size='large'
      fullWidth
    >
      Cerrar
    </Button>
  </React.Fragment>
)

type KoyweKhipuContentProps = {
  providedAction: string
  handleSuccess: () => void
}

const KoyweKhipuContent = ({
  providedAction,
  handleSuccess,
}: KoyweKhipuContentProps) => (
  <React.Fragment>
    <Typography>
      Al hacer clic serás redireccionado a una nueva pestaña para completar la transferencia:
    </Typography>
    <Button
      href={providedAction}
      target='_blank'
      rel='noopener noreferrer'
      onClick={handleSuccess}
      variant='contained'
      size='large'
      fullWidth
    >
      Completar transferencia
    </Button>
  </React.Fragment>
)

type KoyweWidgetProps = {
  purchaseConfig: PurchaseConfig
  createPurchaseQuoteVars?: CreatePurchaseQuoteVars
  paymentMethodDetails?: string
  providedAction?: string
  handleSuccess: () => void
  handleCancel: () => void
}

const KoyweWidget = ({
  purchaseConfig,
  createPurchaseQuoteVars,
  paymentMethodDetails,
  providedAction,
  handleSuccess,
  handleCancel,
}: KoyweWidgetProps) => (
  <Stack
    height='100%'
    alignItems='center'
    justifyContent='center'
    padding={3}
    spacing={3}
  >
    <Stack
      width='100%'
      direction='row'
    >
      <Typography
        flexGrow={1}
        variant='h5'
        component='p'
      >
        Dólares digitales en camino
      </Typography>
      <IconButton
        aria-label='Cerrar'
        onClick={handleCancel}
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          p: 0,
        })}
      >
        <Close />
      </IconButton>
    </Stack>
    {(typeof paymentMethodDetails !== 'undefined' && paymentMethodDetails) ? (
      <KoyweBankTransferContent
        purchaseConfig={purchaseConfig}
        createPurchaseQuoteVars={createPurchaseQuoteVars}
        paymentMethodDetails={paymentMethodDetails}
        handleSuccess={handleSuccess}
      />
    ) : (
      (typeof providedAction !== 'undefined') && (
        <KoyweKhipuContent
          providedAction={providedAction}
          handleSuccess={handleSuccess}
        />
      )
    )}
  </Stack>
)

type FailureWidgetProps = {
  handleClose: () => void
}

const FailureWidget = ({
  handleClose,
}: FailureWidgetProps) => (
  <Stack
    height='100%'
    alignItems='center'
    justifyContent='center'
    padding={2}
    spacing={2}
  >
    <Error />
    <Typography>
      No se puede continuar la compra en tu dispositivo.
      Refresca la página o actualiza tu aplicación.
    </Typography>
    <Button
      onClick={handleClose}
      variant='contained'
    >
      Cerrar
    </Button>
  </Stack>
)

type PaymentStepProps = {
  purchaseConfig: PurchaseConfig
  createPurchaseQuoteVars?: CreatePurchaseQuoteVars
  payment?: Payment
  handleRestart: FlowRestartFnType
}

export const PaymentStep = ({
  purchaseConfig,
  createPurchaseQuoteVars,
  payment,
  handleRestart,
}: PaymentStepProps) => {
  const [modalStatus, setModalStatus] = React.useState<ModalStatus>('OPEN')

  if (!payment) {
    return <Loading />
  }

  const { providerId, providerSpecificKeys, providerSpecificValues } = payment

  const providerSpecificData = Object.fromEntries(
    providerSpecificKeys.map((key, index) => [key, providerSpecificValues[index]]),
  )

  return (
    <React.Fragment>
      {(() => {
        switch (modalStatus) {
        case 'OPEN': return <Loading />
        case 'CANCELLED': return <CancelledMessage handleRestart={handleRestart} />
        case 'SUCCESS': return <SuccessMessage handleRestart={handleRestart} />
        case 'FAILURE': return <FailureMessage handleRestart={handleRestart} />
        }
      })()}
      <Modal
        open={modalStatus === 'OPEN'}
        onClose={() => setModalStatus('CANCELLED')}
      >
        <ModalContainer
          sx={{
            maxWidth: '500px',
            maxHeight: '600px',
            overflow: 'hidden',
          }}
        >
          {(() => {
            switch (providerId) {
            case 'SIMPLEX': return (
              <SimplexWidget
                paymentId={providerSpecificData.paymentId}
                setModalStatus={setModalStatus}
              />
            )
            case 'KOYWE': return (
              <KoyweWidget
                purchaseConfig={purchaseConfig}
                createPurchaseQuoteVars={createPurchaseQuoteVars}
                providedAction={providerSpecificData.providedAction}
                paymentMethodDetails={providerSpecificData.paymentMethodDetails}
                handleSuccess={() => setModalStatus('SUCCESS')}
                handleCancel={() => setModalStatus('CANCELLED')}
              />
            )
            default: return <FailureWidget handleClose={() => setModalStatus('FAILURE')} />
            }
          })()}
        </ModalContainer>
      </Modal>
    </React.Fragment>
  )
}
