import * as React from 'react'

import { MenuItem, Stack } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { EMPLOYMENT_SITUATION_LABELS } from '../queries'

export type OccupationProfileFormValues = {
  employmentSituation: string
  profession: string
  company?: string
  companyIdentificationNumber?: string
  jobTitle?: string
}

export const getOccupationProfileInitialValues = (
  data: OccupationProfileFormValues | undefined,
): OccupationProfileFormValues => ({
  employmentSituation: data?.employmentSituation || '',
  profession: data?.profession || '',
  company: data?.company || '',
  companyIdentificationNumber: data?.companyIdentificationNumber || '',
  jobTitle: data?.jobTitle || '',
})

export const occupationProfileValidationSchema: Yup.SchemaOf<OccupationProfileFormValues> =
  Yup.object().shape({
    employmentSituation: Yup.string()
      .required('Este campo es obligatorio'),
    profession: Yup.string()
      .required('Este campo es obligatorio')
      .min(2, 'Debes ingresar al menos dos caracteres')
      .max(64, 'No puede tener más de 64 caracteres'),
    company: Yup.string()
      .when('employmentSituation', {
        is: 'EMPLOYEE',
        then: Yup.string()
          .required('Este campo es obligatorio')
          .min(2, 'Debes ingresar al menos dos caracteres')
          .max(64, 'No puede tener más de 64 caracteres'),
      }),
    companyIdentificationNumber: Yup.string()
      .when('employmentSituation', {
        is: 'EMPLOYEE',
        then: Yup.string().required('Este campo es obligatorio'),
      }),
    jobTitle: Yup.string()
      .when('employmentSituation', {
        is: 'EMPLOYEE',
        then: Yup.string()
          .required('Este campo es obligatorio')
          .min(2, 'Debes ingresar al menos dos caracteres')
          .max(64, 'No puede tener más de 64 caracteres'),
      }),
  })

export const OccupationProfileFields = () => {
  const { values } = useFormikContext<OccupationProfileFormValues>()

  return (
    <Stack spacing={3}>
      <Field
        required
        name='employmentSituation'
        type='text'
        label='Situación laboral'
        component={Select}
      >
        {Object.entries(EMPLOYMENT_SITUATION_LABELS).map(([situationType, situationLabel]) => (
          <MenuItem
            key={situationType}
            value={situationType}
          >
            {situationLabel}
          </MenuItem>
        ))}
      </Field>
      <Field
        required
        name='profession'
        type='text'
        label='Profesión'
        component={TextField}
      >
      </Field>
      {(values.employmentSituation === 'EMPLOYEE') && (
        <React.Fragment>
          <Field
            required
            name='company'
            type='text'
            label='Empresa'
            component={TextField}
            margin='normal'
          />
          <Field
            required
            name='companyIdentificationNumber'
            type='text'
            label='RUT de la empresa'
            component={TextField}
            margin='normal'
            multiline
          />
          <Field
            required
            name='jobTitle'
            type='text'
            label='Cargo'
            component={TextField}
            margin='normal'
          />
        </React.Fragment>
      )}
    </Stack>
  )
}
