import * as React from 'react'

import { AccountBalance, SwapCalls } from '@mui/icons-material'
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Link } from '@reach/router'

import {
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  Currency,
} from '../../components'

import type { Swap } from '../../queries'

type ConfirmationStepProps = {
  swap?: Swap
  handleRestart: () => void
}

export const ConfirmationStep = ({
  swap,
  handleRestart,
}: ConfirmationStepProps) => (
  <Stack
    spacing={1}
    alignItems='center'
  >
    <Stack
      spacing={1}
      direction='row'
    >
      <AssetBadge
        animated
        symbol={swap?.fromSymbol || ''}
        height={100}
      />
      <AssetBadge
        animated
        symbol={swap?.toSymbol || ''}
        height={100}
      />
    </Stack>
    <Typography
      variant='h6'
      component='span'
      textAlign='center'
    >
      Conversión realizada
    </Typography>
    <List
      disablePadding
      sx={{ width: '100%' }}
    >
      <Divider />
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Monto convertido' />
        <ListItemText
          primary={(
            <Currency
              currency={swap?.fromSymbol || ''}
              value={swap?.fromChangedAmount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Monto recibido' />
        <ListItemText
          primary={(
            <Currency
              currency={swap?.toSymbol || ''}
              value={swap?.toChangedAmount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
    </List>
    <ButtonsContainer sx={{ alignItems: 'flex-end', pt: 1 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          onClick={handleRestart}
          startIcon={<SwapCalls />}
          variant='outlined'
          sx={{ textAlign: 'center' }}
        >
          Volver a convertir
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../portfolio'
          startIcon={<AccountBalance />}
          variant='contained'
          sx={{ textAlign: 'center' }}
        >
          Ir a Portafolio
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Stack>
)
