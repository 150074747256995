import * as React from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { ButtonContainer } from '../components'

import type { ButtonContainerProps } from '../components'

export type GraphUnitButtonsProps = {
  disabled?: boolean
  keys: string[]
  graphUnit: string
  setGraphUnit: (value: string) => void
  containerProps?: ButtonContainerProps
}

export const GraphUnitButtons = ({
  disabled,
  keys,
  graphUnit,
  setGraphUnit,
  containerProps,
}: GraphUnitButtonsProps) => {
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newRange: string | undefined,
  ) => {
    newRange && setGraphUnit(newRange)
  }

  return (
    <ButtonContainer {...containerProps}>
      <ToggleButtonGroup
        disabled={disabled}
        aria-label=''
        color='primary'
        value={graphUnit}
        exclusive
        onChange={handleChange}
      >
        {keys.map((key) => (
          <ToggleButton
            key={key}
            value={key}
            aria-label={key}
            sx={{
              py: 0.5,
              px: 1.5,
              '&.Mui-selected': {
                color: 'text.primary',
              },
            }}
          >
            {key}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ButtonContainer>
  )
}
