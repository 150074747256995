import {
  assetQuotePrice,
  getTimeframeData,
  getTotalAmount,
  getUnspentEarnedAmount,
  getUserAssetAmounts,
  onCommonTimestamp,
  withSymbol,
} from '../services'

import type { MarketAsset, TickerPrice, UserOperation } from '../queries'
import type { AssetTimeframePrice, UserAssetAmount } from '../services'

export type PortfolioBalance = {
  symbol: string
  amounts: UserAssetAmount[]
  totalAmount: number
  earnedAmount: number
  usdPrices: AssetTimeframePrice[]
  usdPrice: number
  quotePrice: number
  marketAssetId: string
  marketAssetIndex: number
}

export const computePortfolioBalances = (
  marketAssets: MarketAsset[],
  operations: UserOperation[],
  tickerPrices: TickerPrice[],
  quoteSymbol: string,
) => {
  const portfolioBalances = marketAssets.map((marketAsset, index) => (
    computePortfolioBalance(marketAsset, quoteSymbol, operations, tickerPrices, index)
  ))

  const quoteTimeframeData = getTimeframeData(tickerPrices, quoteSymbol, quoteSymbol)

  const isQuoteUSDT = quoteSymbol === 'USDT'

  return {
    quoteUsdPrices: quoteTimeframeData.prices || [],
    quoteUsdPrice: isQuoteUSDT ? 1 : quoteTimeframeData.lastPrice,
    portfolioBalances,
  }
}

const computePortfolioBalance = (
  { id, symbol }: MarketAsset,
  quoteSymbol: string,
  operations: UserOperation[],
  tickerPrices: TickerPrice[],
  index: number,
): PortfolioBalance => {
  const assetOperations = withSymbol(operations, symbol)

  const timeframeData = getTimeframeData(tickerPrices, symbol, quoteSymbol)
  const assetUsdPrices = timeframeData.prices
  const assetUsdPrice = timeframeData.lastPrice

  const amounts = getUserAssetAmounts(symbol, assetOperations, assetUsdPrices)
  const totalAmount = getTotalAmount(symbol, assetOperations)
  const earnedAmount = getUnspentEarnedAmount(symbol, assetOperations)

  return {
    symbol,
    amounts,
    totalAmount,
    earnedAmount,
    usdPrices: assetUsdPrices,
    usdPrice: assetUsdPrice,
    quotePrice: assetQuotePrice(tickerPrices, symbol, quoteSymbol),
    marketAssetId: id,
    marketAssetIndex: index,
  }
}

export const computePortfolioQuoteAmounts = (
  quoteUsdPrices: AssetTimeframePrice[],
  portfolioBalances: PortfolioBalance[],
) => {
  let totalQuoteAmount = 0, earnedQuoteAmount = 0
  const touchedTimestamps = new Set<number>()

  const totalQuoteAmounts = quoteUsdPrices.map((price) => ({
    timestamp: price.timestamp,
    value: 0,
  }))

  portfolioBalances.forEach((portfolioBalance) => {
    totalQuoteAmount += portfolioBalance.totalAmount * portfolioBalance.quotePrice
    earnedQuoteAmount += portfolioBalance.earnedAmount * portfolioBalance.quotePrice

    onCommonTimestamp(totalQuoteAmounts, portfolioBalance.amounts, (acc, assetAmount) => {
      acc.value += assetAmount.quoteValue
      touchedTimestamps.add(acc.timestamp)
    })
  })

  return {
    totalQuoteAmounts: totalQuoteAmounts.filter(({ timestamp }) => touchedTimestamps.has(timestamp)),
    totalQuoteAmount,
    earnedQuoteAmount,
  }
}
