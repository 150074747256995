import * as React from 'react'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Slider from 'react-slick'

type ProfileCardProps = {
  title: string
  hoverText: string
  image: string
  content: React.ReactNode
}

const ProfileCard = ({
  title,
  hoverText,
  image,
  content,
}: ProfileCardProps) => {
  const [hover, setHover] = React.useState(false)
  const theme = useTheme()

  const hoverStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    textAlign: 'center',
    transition: 'transform 0.5s ease, opacity 0.5s ease',
    transform: 'scale(1)',
    opacity: 0,
    ...(hover && {
      transform: 'scale(1.05)',
      opacity: 1,
    }),
  }

  return (
    <Card
      elevation={0}
      sx={(theme) => ({
        mx: 1,
        [theme.breakpoints.up('sm')]: { mx: 3 },
      })}
    >
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ position: 'relative' }}
      >
        <CardMedia
          component='img'
          height='350'
          image={image}
          alt={`Imagen de ${title}`}
          sx={{
            transition: 'transform 0.5s ease',
            transform: 'scale(1)',
            ...(hover &&  { transform: 'scale(1.05)' }),
          }}
        />
        <div style={hoverStyle}>
          <Typography variant='caption'>
            {hoverText}
          </Typography>
        </div>
      </div>
      <CardContent>
        {content}
      </CardContent>
    </Card>
  )
}

type CarouselData = {
  title: string
  hoverText: string
  image: string
  content: React.ReactNode
}[]

export const Carousel = ({ data }: { data: CarouselData }) => {
  const theme = useTheme()
  const mx = useMediaQuery(theme.breakpoints.up('sm')) ? 0 : theme.spacing(1)

  React.useEffect(() => {
    const primaryColor = (theme.palette.mode === 'light')
      ? theme.palette.primary.dark
      : theme.palette.primary.light
    const style = document.createElement('style')
    style.textContent = `
      .slick-dots li button:before {
        color: ${primaryColor} !important;
      }
      .slick-dots li.slick-active button:before {
        color: ${primaryColor} !important;
      }
      .slick-prev:before, .slick-next:before {
        color: ${primaryColor} !important;
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [theme])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnDotsHover: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div style={{ marginRight: mx, marginLeft: mx }}>
      <Slider {...settings}>
        {data.map((value) => (
          <ProfileCard
            key={value.title}
            {...value}
          />
        ))}
      </Slider>
    </div>
  )
}
