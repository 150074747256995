import * as React from 'react'

export type AppContextType = {
  animatedLogo: string
  appName: string
  appTitle: string
  defaultOgImage: string
  simplexPublicKey: string
  simplexScriptSrc: string
  supportEmail: string
}

const createInitialContext = (): AppContextType => ({
  animatedLogo: '',
  appName: '',
  appTitle: '',
  defaultOgImage: '',
  simplexPublicKey: '',
  simplexScriptSrc: '',
  supportEmail: '',
})

export const AppContext = React.createContext(createInitialContext())
