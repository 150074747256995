import * as React from 'react'

import {
  Table as BaseTable,
  LinearProgress,
  Stack,
  TableCell,
  Typography,
  styled,
} from '@mui/material'

import { AssetBadge, Currency } from '../components'
import { percentFormatter } from '../services'

import type { CurrencyProps } from '../components'
import type { LinearProgressProps } from '@mui/material'

export const Table = styled(BaseTable)(({ theme }) => ({
  '.MuiTableCell-head': {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiTableCell-head, & .MuiTableCell-body': {
    borderLeft: `1px solid ${theme.palette.action.disabled}`,
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
  },
  '& .MuiTableCell-head:first-of-type, & .MuiTableCell-body:first-of-type': {
    borderLeft: 'none',
  },
  '& .MuiTableRow-root:last-of-type > td': {
    borderBottom: 'none',
  },
}))

type TokenCellProps = {
  name: string
  symbol: string
}

export const TokenCell = ({ name, symbol }: TokenCellProps) => (
  <TableCell>
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
    >
      <AssetBadge
        symbol={symbol}
        height={32}
      />
      <Typography
        variant='body2'
        fontWeight='medium'
      >
        {name}
      </Typography>
    </Stack>
  </TableCell>
)

type CurrencyCellProps = CurrencyProps

export const CurrencyCell = ({
  currency, value, digits,
}: CurrencyCellProps) => (
  <TableCell align='right'>
    <Typography
      color='text.primary'
      component='span'
    >
      <Currency
        currency={currency}
        value={value}
        digits={digits}
      />
    </Typography>
  </TableCell>
)

type DualCurrencyCellProps = {
  primaryCurrency: string
  primaryValue: number
  secondaryCurrency: string
  secondaryValue: number
}

export const DualCurrencyCell = ({
  primaryCurrency, primaryValue,
  secondaryCurrency, secondaryValue,
}: DualCurrencyCellProps) => (
  <TableCell align='right'>
    <Typography
      color='text.primary'
      variant='body2'
      component='span'
    >
      <Currency
        currency={primaryCurrency}
        value={primaryValue}
      />
    </Typography>
    <br />
    <Typography
      color='text.secondary'
      variant='body2'
      component='span'
    >
      <Currency
        currency={secondaryCurrency}
        value={secondaryValue}
      />
    </Typography>
  </TableCell>
)

type PercentageCellProps = {
  value: number
  color?: LinearProgressProps['color']
}

export const PercentageCell = ({ value, color }: PercentageCellProps) => (
  <TableCell align={color ? 'left' : 'right'}>
    <Typography variant={color ? 'caption' : 'body1'}>
      {percentFormatter.format(value / 100.0)}
    </Typography>
    {color && (
      <LinearProgress
        variant='determinate'
        value={value}
        color={color}
      />
    )}
  </TableCell>
)
