import * as React from 'react'

import type { ConversionCurrency, QuoteSymbol } from '../queries'

export type DomainContextType = {
  quoteSymbol?: QuoteSymbol
  conversionCurrency?: ConversionCurrency
}

const createInitialContext = (): DomainContextType => ({
  quoteSymbol: 'BTC',
  conversionCurrency: 'CLP',
})

export const DomainContext = React.createContext(createInitialContext())
