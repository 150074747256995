import { getChangedAmount } from '../services'

import type { PriceGraphPoint } from '../components'
import type { UserOperation } from '../queries'

export type UserAssetAmount = PriceGraphPoint & {
  quoteValue: number
}

export const getUserAssetAmounts = (
  symbol: string,
  operations: UserOperation[],
  timeframePrices: UserAssetAmount[],
): UserAssetAmount[] => {
  const amounts: UserAssetAmount[] = []

  if (!operations?.length || !timeframePrices?.length) {
    return amounts
  }

  const sortedOperations = operations.sort((a, b) => a.timestamp - b.timestamp)
  let timestampsIndex = 0, previousAmount = 0

  sortedOperations.forEach((operation, index) => {
    const afterOpAmount = previousAmount + 1000 * getChangedAmount(operation, symbol)
    const nextOpTimestamp = sortedOperations[index + 1]?.timestamp

    for (;;) {
      const timestamp = timeframePrices[timestampsIndex]?.timestamp

      if (!timestamp || (nextOpTimestamp && nextOpTimestamp < timestamp)) {
        previousAmount = afterOpAmount
        return
      }

      const value = (timestamp < operation.timestamp ? previousAmount : afterOpAmount) / 1000
      amounts.push({
        timestamp,
        value,
        quoteValue: value * timeframePrices[timestampsIndex].quoteValue,
      })
      timestampsIndex++
    }
  })

  return amounts
}

export const getUserAssetPriceData = (
  assetAmounts: PriceGraphPoint[],
  timeframePrices: PriceGraphPoint[],
  useTimeframePrices: boolean,
) => {
  const assetPrices = useTimeframePrices
    ? getUserAssetPrices(assetAmounts, timeframePrices)
    : assetAmounts

  const ath = Math.max(...assetPrices.map((assetPrice) => assetPrice.value))
  const atl = Math.min(...assetPrices.map((assetPrice) => assetPrice.value))

  const variation = (assetPrices.length > 1)
    ? assetPrices[assetPrices.length - 1].value - assetPrices[0].value
    : 0

  return {
    assetPrices,
    ath,
    atl,
    variation,
  }
}

const getUserAssetPrices = (
  assetAmounts: PriceGraphPoint[],
  timeframePrices: PriceGraphPoint[],
): PriceGraphPoint[] => {
  const prices: PriceGraphPoint[] = []

  onCommonTimestamp(assetAmounts, timeframePrices, (amount, price) => {
    prices.push({
      timestamp: amount.timestamp,
      value: amount.value * price.value,
    })
  })

  return prices
}

export const onCommonTimestamp = <aType extends PriceGraphPoint, bType extends PriceGraphPoint>(
  a: aType[],
  b: bType[],
  reducer: (aPoint: aType, bPoint: bType) => void,
) => {
  let aIndex = 0, bIndex = 0

  while (aIndex < a.length && bIndex < b.length) {
    const aPoint = a[aIndex]
    const bPoint = b[bIndex]

    const aTimestamp = aPoint.timestamp
    const bTimestamp = bPoint.timestamp

    if (aTimestamp < bTimestamp) {
      aIndex++
      continue
    }
    if (aTimestamp > bTimestamp) {
      bIndex++
      continue
    }

    reducer(aPoint, bPoint)
    aIndex++
    bIndex++
  }
}
