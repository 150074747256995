import * as React from 'react'

import { Skeleton, Stack, Typography } from '@mui/material'

import { Currency } from '../../components'
import { getSymbol } from '../../services'

import type { MarketAsset } from '../../queries'
import type { PortfolioBalance } from  '../../services'

type BalanceDisplayProps = {
  sadMode: boolean
  marketAsset: MarketAsset | null
  portfolioBalance: PortfolioBalance | null
  secondaryCurrency: string
  price: number
}

export const BalanceDisplay = ({
  sadMode,
  marketAsset,
  portfolioBalance,
  secondaryCurrency,
  price,
}: BalanceDisplayProps) => (
  <Stack>
    <Typography
      variant='h4'
      component='span'
      fontWeight='300'
      sx={{ opacity: sadMode ? 0.5 : 1 }}
    >
      {(marketAsset !== null && portfolioBalance !== null) ? (
        <Currency
          currency={getSymbol(marketAsset.symbol)}
          value={portfolioBalance.totalAmount}
        />
      ) : (
        <Skeleton width={180} />
      )}
    </Typography>
    <Typography
      variant='body1'
      component='span'
      fontWeight='300'
      sx={{ opacity: sadMode ? 0.5 : 1 }}
    >
      {(portfolioBalance !== null) ? (
        <Currency
          currency={secondaryCurrency}
          value={portfolioBalance.totalAmount * price}
        />
      ) : (
        <Skeleton />
      )}
    </Typography>
  </Stack>
)
