import { gql } from '@apollo/client'

export type MarketAsset = {
  id: string
  name: string
  symbol: string
  circulatingSupply?: number
  totalSupply?: number
  maxSupply?: number
  blockchains: string[]
}

export const MARKET_ASSET_FIELDS = gql`
  fragment MarketAssetFields on MarketAsset {
    id
    name
    symbol
    circulatingSupply
    totalSupply
    maxSupply
    blockchains
  }
`

export type MarketAssetVars = {
  symbol: string
}

export type MarketAssetData = {
  marketAsset: MarketAsset
}

export const MARKET_ASSET_QUERY = gql`
  ${MARKET_ASSET_FIELDS}
  query MarketAsset(
    $symbol: String!
  ) {
    marketAsset(
      symbol: $symbol
    ) {
      ...MarketAssetFields
    }
  }
`

export type MarketAssetsVars = Record<string, never>

export type MarketAssetsData = {
  marketAssets: MarketAsset[]
}

export const MARKET_ASSETS_QUERY = gql`
  ${MARKET_ASSET_FIELDS}
  query MarketAssets {
    marketAssets {
      ...MarketAssetFields
    }
  }
`
