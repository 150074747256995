import type { TickerPrice } from '../queries'

export const getAssetTickerPrices = (
  tickerPrices: TickerPrice[],
  symbol: string,
): TickerPrice[] => (
  tickerPrices
    .filter((tickerPrice) => tickerPrice.baseSymbol === symbol)
    .sort((a, b) => a.openTimestamp - b.openTimestamp)
    .map(({ openTimestamp, ...rest }) => ({ openTimestamp: openTimestamp * 1000, ...rest }))
)

export const assetQuotePrice = (
  tickerPrices: TickerPrice[],
  symbol: string,
  quoteSymbol: string,
) => {
  if (quoteSymbol === getSymbol(symbol)) {
    return 1
  }

  const prices = getAssetTickerPrices(tickerPrices, symbol)
  return prices?.length ? prices[prices.length - 1].openPrice : 0
}

export const hourlyTimestamp = () => {
  const timestampSeconds = Math.floor(Date.now() / 1000)
  return timestampSeconds - (timestampSeconds % 3600)
}

export const getSymbol = (symbol?: string) => {
  if (!symbol) {
    return ''
  }
  return symbol.split('_')[0]
}

export const getSymbolDisplay = (symbol: string) => (
  symbol.split('_').length > 1 ? symbol.split('_')[1] : symbol.split('_')[0]
)
