import * as React from 'react'

import { Typography } from '@mui/material'

import {
  CopyButton,
  MonospaceText,
} from '../components'

import type { DepositAddress } from '../queries'

type AddressDisplayProps = {
  blockchain: string
  address: DepositAddress
}

export const AddressDisplay = ({ blockchain, address }: AddressDisplayProps) => (
  <React.Fragment>
    <Typography textAlign='center'>
      Tu dirección para depósito en&nbsp;la&nbsp;
      <span style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
        {blockchain} network
      </span>
      &nbsp;es:
    </Typography>
    <MonospaceText textAlign='center'>
      {address.value}
    </MonospaceText>
    <CopyButton
      textToCopy={address.value}
      buttonText='Copiar dirección'
      buttonPressedText='¡Dirección copiada!'
    />
  </React.Fragment>
)
