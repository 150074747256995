import { gql } from '@apollo/client'

export type SaleQuote = {
  id: string
  providerId: string
  fiatAmount: number
  remainingSeconds: number
}

export type CreateSaleQuoteVars = {
  bankAccountId: string
  cryptoAmount: number
}

export type CreateSaleQuoteData = {
  createSaleQuote: SaleQuote
}

export const CREATE_SALE_QUOTE_MUTATION = gql`
  mutation CreateSaleQuote(
    $bankAccountId: ID!
    $cryptoAmount: Float!
  ) {
    createSaleQuote(
      input: {
        bankAccountId: $bankAccountId
        cryptoAmount: $cryptoAmount
      }
    ) {
      id
      providerId
      fiatAmount
      remainingSeconds
    }
  }
`
