import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Typography } from '@mui/material'

import { QuotePreview } from './quote_preview'
import { ButtonContainer, ButtonsContainer } from '../../components'
import { CREATE_PAYMENT_MUTATION, CREATE_PURCHASE_QUOTE_MUTATION } from '../../queries'
import { humanTimeFormatter, translateError } from '../../services'

import type {
  CreatePaymentData,
  CreatePaymentVars,
  CreatePurchaseQuoteData,
  CreatePurchaseQuoteVars,
  Payment,
  PurchaseConfig,
  PurchaseQuote,
} from '../../queries'

type QuoteConfirmationStepProps = {
  purchaseConfig?: PurchaseConfig
  createPurchaseQuoteVars?: CreatePurchaseQuoteVars
  handleBack: () => void
  handleNext: (payment?: Payment) => void
}

export const QuoteConfirmationStep = ({
  purchaseConfig,
  createPurchaseQuoteVars,
  handleBack,
  handleNext,
}: QuoteConfirmationStepProps) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()
  const [purchaseQuote, setPurchaseQuote] = React.useState<PurchaseQuote>()
  const [quoteRemainingTime, setQuoteRemainingTime] = React.useState<number>()

  const [createQuote] =
    useMutation<CreatePurchaseQuoteData, CreatePurchaseQuoteVars>(CREATE_PURCHASE_QUOTE_MUTATION, {
      errorPolicy: 'all',
    })

  const [createPayment] =
    useMutation<CreatePaymentData, CreatePaymentVars>(CREATE_PAYMENT_MUTATION, {
      errorPolicy: 'all',
    })

  const clearError = () => setErrorMsg(undefined)

  const handleCreateQuote = React.useCallback(async () => {
    setSubmitting(true)
    clearError()
    const response = await createQuote({ variables: createPurchaseQuoteVars })
    const newQuote = response.data?.createPurchaseQuote

    if (newQuote) {
      setPurchaseQuote(newQuote)
      setQuoteRemainingTime(newQuote.remainingSeconds)
    } else {
      setErrorMsg(translateError(response))
    }

    setSubmitting(false)
  }, [createPurchaseQuoteVars, createQuote, setErrorMsg])

  const handleCreatePayment = async () => {
    if (!purchaseQuote) {
      return
    }

    setSubmitting(true)
    clearError()
    const response = await createPayment({
      variables: {
        quoteId: purchaseQuote.id,
        providerId: purchaseQuote.providerId,
      },
    })
    const payment = response.data?.createPayment

    if (payment) {
      handleNext(payment)
    } else {
      setErrorMsg(translateError(response))
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    handleCreateQuote()
  }, [handleCreateQuote])

  React.useEffect(() => {
    const updateRemainingTime = () => {
      setQuoteRemainingTime((remainingTime) => {
        if (typeof remainingTime === 'undefined') {
          return undefined
        }

        return Math.max(remainingTime - 1, 0)
      })
    }
    const interval = setInterval(updateRemainingTime, 1000)
    return () => clearInterval(interval)
  }, [purchaseQuote])

  const quoteExpired = quoteRemainingTime === 0
  const hasError = typeof errorMsg !== 'undefined'

  return (
    <React.Fragment>
      <Typography
        variant='h6'
        component='span'
        textAlign='center'
        gutterBottom
      >
        Orden de compra
      </Typography>
      <QuotePreview
        purchaseConfig={purchaseConfig}
        createPurchaseQuoteVars={createPurchaseQuoteVars}
        purchaseQuote={purchaseQuote}
        errorMsg={errorMsg}
      />
      {(!hasError && typeof quoteRemainingTime !== 'undefined') && (
        <Typography
          align='center'
          mt={2}
          mb={1}
        >
          {(quoteExpired) ? (
            <React.Fragment>
              El precio calculado expiró (presiona&nbsp;para&nbsp;actualizar)
            </React.Fragment>
          ) : (
            `Este precio expirará en ${humanTimeFormatter(quoteRemainingTime)}`
          )}
        </Typography>
      )}
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={submitting}
            onClick={handleBack}
            variant='outlined'
          >
            Regresar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={submitting || hasError || !purchaseQuote || !createPurchaseQuoteVars}
            onClick={() => quoteExpired ? handleCreateQuote() : handleCreatePayment()}
            variant='contained'
          >
            {quoteExpired ? 'Actualizar' : 'Continuar' }
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </React.Fragment>
  )
}
