import * as React from 'react'

import { CreditCard } from '@mui/icons-material'
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material'
import { Link } from '@reach/router'

import { AppContainer, AssetBadge } from '../components'

import type { MarketAsset } from '../queries'

type MarketAssetTitleProps = {
  marketAsset?: MarketAsset
}

export const MarketAssetTitle = ({
  marketAsset,
}: MarketAssetTitleProps) => (
  <AppContainer
    md={6}
    sx={{ p: 3, height: '100%' }}
  >
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
      sx={{ height: '100%' }}
    >
      {(marketAsset) ? (
        <AssetBadge
          symbol={marketAsset.symbol}
          height={48}
        />
      ) : (
        <Skeleton
          variant='circular'
          width={48}
          height={48}
        />
      )}
      <Typography
        variant='h5'
        component='p'
        fontWeight='medium'
        lineHeight={1}
      >
        {(marketAsset) ? marketAsset.name : <Skeleton width={120} />}
        <br />
        <Typography
          component='span'
          color='text.secondary'
          lineHeight={1}
        >
          {(marketAsset) ? marketAsset.symbol : <Skeleton />}
        </Typography>
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          component={Link}
          to='../../purchase'
          startIcon={<CreditCard />}
          variant='contained'
        >
          Comprar
        </Button>
      </Box>
    </Stack>
  </AppContainer>
)
