import * as React from 'react'

import { Button, Typography } from '@mui/material'

import { BankAccountForm } from './bank_account_form'
import { ButtonContainer, ButtonsContainer } from '../../components'
import { BANKS } from '../../services'

import type { BankAccount, BankConfig } from '../../queries'

type AddressStepProps = {
  bankConfig: BankConfig
  bankAccount: BankAccount | null
  handleBack: () => void
  handleNext: (bankAccount: BankAccount) => void
}

export const BankAccountStep = ({
  bankConfig,
  bankAccount,
  handleBack,
  handleNext,
}: AddressStepProps) => {
  const [selectedBankAccount, setBankAccount] = React.useState<BankAccount | null>(bankAccount)

  return (
    <React.Fragment>
      <Typography
        variant='h6'
        component='span'
        textAlign='center'
      >
        Retirar al blockchain
      </Typography>
      <ButtonsContainer sx={{ alignItems: 'flex-end', pt: 1 }}>
        {bankConfig.userBankAccounts.map((bankAccount) => (
          <ButtonContainer
            key={bankAccount.id}
            xs={12}
          >
            <Button
              fullWidth
              onClick={() => setBankAccount(bankAccount)}
              variant={(selectedBankAccount?.id === bankAccount.id) ? 'contained' : 'outlined'}
              size='large'
              sx={{ display: 'flex', gap: 1 }}
            >
              <span style={{ display: 'inline-block', fontWeight: 'bold' }}>
                {BANKS[bankAccount.bankCode].label}
              </span>
              <span>-</span>
              {bankAccount.number}
            </Button>
          </ButtonContainer>
        ))}
        <ButtonContainer xs={12}>
          <BankAccountForm bankConfig={bankConfig} />
        </ButtonContainer>
      </ButtonsContainer>
      <ButtonsContainer sx={{ alignItems: 'flex-end', pt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            onClick={handleBack}
            variant='outlined'
          >
            Regresar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={!selectedBankAccount}
            onClick={() => selectedBankAccount && handleNext(selectedBankAccount)}
            variant='contained'
          >
            Continuar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </React.Fragment>
  )
}
