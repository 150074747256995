import * as React from 'react'

import { AccountBalance } from '@mui/icons-material'
import { Button, InputAdornment, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer } from '../../components'

import type { CreatePurchaseQuoteVars, PurchaseConfig, PurchaseMethod } from '../../queries'
import type { FormikProps } from 'formik'

type PurchaseOption = {
  method: PurchaseMethod
  icon: React.ReactNode
  label: string
}

const PURCHASE_OPTIONS: PurchaseOption[] = [
  {
    method: 'BANK_TRANSFER',
    icon: <AccountBalance fontSize='small' />,
    label: 'Transferencia Bancaria',
  },
]

const availablePurchaseOptions = (purchaseConfig: PurchaseConfig) => (
  PURCHASE_OPTIONS.filter(({ method }) => purchaseConfig.purchaseMethods.includes(method))
)

const defaultPurchaseMethod = (purchaseConfig: PurchaseConfig) => {
  const available = availablePurchaseOptions(purchaseConfig)

  return (available.length === 1) ? available[0].method : ''
}

type FormValues = {
  fiatAmount: number
  purchaseMethod: string
}

const initialValues = (
  purchaseConfig: PurchaseConfig,
  createPurchaseQuoteVars?: CreatePurchaseQuoteVars,
) => ({
  fiatAmount: createPurchaseQuoteVars?.fiatAmount || 0,
  purchaseMethod: createPurchaseQuoteVars?.purchaseMethod || defaultPurchaseMethod(purchaseConfig),
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    fiatAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .integer('Debes introducir un monto sin decimales'),
    purchaseMethod: Yup.string()
      .required('Este campo es obligatorio'),
  })

type PurchaseFormProps = FormikProps<FormValues> & {
  purchaseConfig: PurchaseConfig
}

const PurchaseForm = ({
  isSubmitting,
  isValid,
  setFieldValue,
  submitForm,
  values,
  purchaseConfig,
}: PurchaseFormProps) => (
  <Form
    onSubmit={(event) => {
      event?.preventDefault()
      submitForm()
    }}
    style={{ display: 'flex', flexDirection: 'column' }}
  >
    <Field
      required
      name='fiatAmount'
      type='number'
      label='Monto a comprar'
      component={TextField}
      disabled={purchaseConfig.purchaseMethods.length < 1}
      inputProps={{
        autoComplete: 'off',
        inputMode: 'numeric',
        pattern: '[0-9]+',
        min: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>{purchaseConfig.userFiatCurrency} $</InputAdornment>
        ),
        onClick: (event: React.MouseEvent<HTMLInputElement>) => {
          event.currentTarget.querySelector('input')?.select()
        },
      }}
      sx={{
        flexGrow: 1,
        my: 2,
      }}
    />
    <Typography
      variant='body2'
      color='text.secondary'

    >
      Selecciona un método de pago:
    </Typography>
    <ButtonsContainer sx={{ mt: 1 }}>
      {availablePurchaseOptions(purchaseConfig).map((purchaseOption, index) => (
        <ButtonContainer
          key={index}
          xs={12}
        >
          <Button
            fullWidth
            disabled={isSubmitting}
            startIcon={purchaseOption.icon}
            onClick={() => setFieldValue('purchaseMethod', purchaseOption.method)}
            variant={(values.purchaseMethod === purchaseOption.method) ? 'contained' : 'outlined'}
            color='primary'
            size='large'
            sx={(theme) => ({
              py: 2,
              background: (values.purchaseMethod === purchaseOption.method)
                ? theme.palette.primary.main
                : 'transparent',
            })}
          >
            {purchaseOption.label}
          </Button>
        </ButtonContainer>
      ))}
    </ButtonsContainer>
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
          type='submit'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type QuoteVarsStepProps = {
  purchaseConfig: PurchaseConfig
  createPurchaseQuoteVars?: CreatePurchaseQuoteVars
  handleNext: (vars: CreatePurchaseQuoteVars) => void
}

export const QuoteVarsStep = ({
  purchaseConfig,
  createPurchaseQuoteVars,
  handleNext,
}: QuoteVarsStepProps) => (
  <React.Fragment>
    <Typography
      variant='h6'
      component='span'
      textAlign='center'
      gutterBottom
    >
      Comprar dólares digitales
    </Typography>
    <Formik
      initialValues={initialValues(purchaseConfig, createPurchaseQuoteVars)}
      validationSchema={validationSchema}
      onSubmit={(values) => handleNext({
        fiatAmount: values.fiatAmount,
        purchaseMethod: values.purchaseMethod as PurchaseMethod,
      })}
    >
      {(props) => (
        <PurchaseForm
          purchaseConfig={purchaseConfig}
          {...props}
        />
      )}
    </Formik>
  </React.Fragment>
)
