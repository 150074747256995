import { gql } from '@apollo/client'

export type TickerPrice = {
  id: string
  baseSymbol: string
  openTimestamp: number
  openPrice: number
  volume: number
}

export const TICKER_PRICE_FIELDS = gql`
  fragment TickerPriceFields on TickerPrice {
    id
    baseSymbol
    openTimestamp
    openPrice
    volume
  }
`

export type TickerPricesVars = {
  symbol: string
  range: '24h' | '7d' | '30d' | '1y' | 'all'
  endTimestamp: number
}

export type TickerPricesData = {
  tickerPrices: TickerPrice[]
}

export const TICKER_PRICES_QUERY = gql`
  ${TICKER_PRICE_FIELDS}
  query AssetTickerPrices(
    $symbol: String!
    $range: String!
    $endTimestamp: Int!
  ) {
    tickerPrices(
      symbol: $symbol
      range: $range
      endTimestamp: $endTimestamp
    ) {
      ...TickerPriceFields
    }
  }
`

export type AllTickerPricesVars = {
  range: '24h' | '7d'
  endTimestamp: number
}

export type AllTickerPricesData = {
  allTickerPrices: TickerPrice[]
}

export const ALL_TICKER_PRICES_QUERY = gql`
  ${TICKER_PRICE_FIELDS}
  query AllTickerPrices(
    $range: String!
    $endTimestamp: Int!
  ) {
    allTickerPrices(
      range: $range
      endTimestamp: $endTimestamp
    ) {
      ...TickerPriceFields
    }
  }
`
