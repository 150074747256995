import { gql } from '@apollo/client'

export type CreateBankWithdrawalVars = {
  authorizationCode: string
  bankAccountId: string
  providerId: string
  quoteId: string
}

export type CreateBankWithdrawalData = {
  createBankWithdrawal: string
}

export const CREATE_BANK_WITHDRAWAL_MUTATION = gql`
  mutation CreateBankWithdrawal(
    $authorizationCode: String!
    $bankAccountId: ID!
    $providerId: String!
    $quoteId: String!
  ) {
    createBankWithdrawal(
      input: {
        authorizationCode: $authorizationCode
        bankAccountId: $bankAccountId
        providerId: $providerId
        quoteId: $quoteId
      }
    )
  }
`

export type CreateBlockchainWithdrawalVars = {
  marketAssetId: string
  blockchain: string
  address: string
  amount: number
  authorizationCode: string
}

export type CreateBlockchainWithdrawalData = {
  createBlockchainWithdrawal: string
}

export const CREATE_BLOCKCHAIN_WITHDRAWAL_MUTATION = gql`
  mutation CreateBlockchainWithdrawal(
    $marketAssetId: ID!
    $blockchain: String!
    $address: String!
    $amount: Float!
    $authorizationCode: String!
  ) {
    createBlockchainWithdrawal(
      input: {
        marketAssetId: $marketAssetId
        blockchain: $blockchain
        address: $address
        amount: $amount
        authorizationCode: $authorizationCode
      }
    )
  }
`
