import { gql } from '@apollo/client'

type NotificationType = 'PURCHASE' | 'SWAP' | 'WITHDRAW_BANK'

export type NotifyUserRequestVars = {
  content: string[]
  notificationType: NotificationType
}

export type NotifyUserRequestData = {
  notifyUserRequest: string[]
}

export const NOTIFY_USER_REQUEST_MUTATION = gql`
  mutation NotifyUserRequest(
    $content: [String!]!
    $notificationType: String!
  ) {
    notifyUserRequest(
      input: {
        content: $content
        notificationType: $notificationType
      }
    )
  }
`
