const ELEMENT_ID = 'simplex_script'

declare global {
  interface Window {
    simplexAsyncFunction: () => void
  }
}

type LoadSimplexArgs = {
  publicKey: string
  scriptSrc: string
  callback: () => void
}

export const loadSimplex = ({ publicKey, scriptSrc, callback }: LoadSimplexArgs) => {
  const existingScript = document.getElementById(ELEMENT_ID)

  if (!existingScript) {
    window.simplexAsyncFunction = () => {
      Simplex.init({ public_key: publicKey })
    }

    const script = document.createElement('script')
    script.src = scriptSrc
    script.id = ELEMENT_ID
    document.body.appendChild(script)

    script.onload = () => {
      if (callback) {
        callback()
      }
    }
  }

  if (existingScript && callback) {
    callback()
  }
}
