import { gql } from '@apollo/client'

import { STORAGE_POST_FIELDS } from './storage_post'

import type { StoragePost } from './storage_post'

export type PoiContentType = 'application/pdf' | 'image/heic' | 'image/jpeg' | 'image/jpg' | 'image/png'

export const POI_CONTENT_TYPE_LABELS: { [key in PoiContentType]: string } = {
  'application/pdf': 'Documento PDF',
  'image/heic': 'Imagen HEIC',
  'image/jpeg': 'Imagen JPEG',
  'image/jpg': 'Imagen JPG',
  'image/png': 'Imagen PNG',
}

export type PersonDocumentType = 'ID_CARD_FRONT' | 'ID_CARD_BACK' | 'PASSPORT'

export const PERSON_DOCUMENT_TYPE_LABELS: { [key in PersonDocumentType]: string } = {
  'ID_CARD_FRONT': 'Carnet de identidad (frente)',
  'ID_CARD_BACK': 'Carnet de identidad (reverso)',
  'PASSPORT': 'Pasaporte',
}

export type BusinessDocumentType = 'VALIDITY_CERTIFICATE' | 'COMPANY_CONSTITUTION'

export const BUSINESS_DOCUMENT_TYPE_LABELS: { [key in BusinessDocumentType]: string } = {
  'VALIDITY_CERTIFICATE': 'Certificado de vigencia',
  'COMPANY_CONSTITUTION': 'Constitución de la empresa',
}

export type PoiDocumentType = PersonDocumentType | BusinessDocumentType

export const POI_DOCUMENT_TYPE_LABELS = {
  ...PERSON_DOCUMENT_TYPE_LABELS,
  ...BUSINESS_DOCUMENT_TYPE_LABELS,
}

export type LegalIdentityProof = {
  id: string
  contentType: string
  documentType: PoiDocumentType
  expirationDate?: string
  storagePost: StoragePost
}

export const LEGAL_IDENTITY_PROOF_FIELDS = gql`
  ${STORAGE_POST_FIELDS}
  fragment LegalIdentityProofFields on LegalIdentityProof {
    id
    contentType
    documentType
    expirationDate
    storagePost {
      ...StoragePostFields
    }
  }
`

export type CreateLegalIdentityProofsVars = {
  contentTypeBack?: string
  contentTypeFront: string
  documentType: string
  expirationDate: string | null
}

export type CreateLegalIdentityProofsData = {
  createLegalIdentityProofs: LegalIdentityProof[]
}

export const CREATE_LEGAL_IDENTITY_PROOFS_MUTATION = gql`
  ${LEGAL_IDENTITY_PROOF_FIELDS}
  mutation CreateLegalIdentityProofs(
    $contentTypeBack: String
    $contentTypeFront: String!
    $documentType: String!
    $expirationDate: ISO8601Date
  ) {
    createLegalIdentityProofs(
      input: {
        contentTypeBack: $contentTypeBack
        contentTypeFront: $contentTypeFront
        documentType: $documentType
        expirationDate: $expirationDate
      }
    ) {
      ...LegalIdentityProofFields
    }
  }
`

export type UpdateLegalIdentityProofVars = {
  id: string
}

export type UpdateLegalIdentityProofData = {
  updateLegalIdentityProof: string
}

export const UPDATE_LEGAL_IDENTITY_PROOF_MUTATION = gql`
  mutation UpdateLegalIdentityProof(
    $id: ID!
  ) {
    updateLegalIdentityProof(
      input: {
        id: $id
      }
    )
  }
`

export type LegalIdentityProofView = {
  id: string
  contentType: PoiContentType
  documentType: PoiDocumentType
  expirationDate?: string
  storageUrl?: string
}

export const LEGAL_IDENTITY_PROOF_VIEW_FIELDS = gql`
  fragment LegalIdentityProofViewFields on LegalIdentityProofView {
    id
    contentType
    documentType
    expirationDate
    storageUrl
  }
`

export type LegalIdentityProofsVars = Record<string, never>

export type LegalIdentityProofsData = {
  legalIdentityProofs: LegalIdentityProofView[]
}

export const LEGAL_IDENTITY_PROOFS_QUERY = gql`
  ${LEGAL_IDENTITY_PROOF_VIEW_FIELDS}
  query LegalIdentityProofs {
    legalIdentityProofs {
      ...LegalIdentityProofViewFields
    }
  }
`
