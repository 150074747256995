import * as React from 'react'

import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import { Group } from '@visx/group'
import { Treemap, hierarchy, stratify, treemapBinary as tile } from '@visx/hierarchy'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { scaleLinear, scaleLog } from '@visx/scale'

import { getSymbolDisplay, percentFormatter } from '../../services'

import type { Theme } from '@mui/material'

type PortfolioCompositionData = {
  id: string
  parent: string | null
  size: number | null
}

const buildColorScale = (theme: Theme, count: number) => (
  scaleLinear<string>({
    domain: [
      1,
      count,
    ],
    range: [
      theme.palette.primary.dark,
      theme.palette.primary.main,
    ],
  })
)

const buildAmountScale = (data: PortfolioCompositionData[]) => (
  scaleLinear<number>({
    domain: [
      Math.min(...data.map((item) => item.size || 0)),
      Math.max(...data.map((item) => item.size || 0)),
    ],
    range: [
      0.08,
      1,
    ],
  })
)

const textScale = scaleLog<number>({
  domain: [
    0.08,
    1,
  ],
  range: [
    0.75,
    2,
  ],
})

type AssetPercentTextProps = {
  nodeHeight: number
  nodeWidth: number
  nodePercent: number
  nodeSize: number
  symbol: string
}

const AssetPercentText = ({
  nodeHeight,
  nodeWidth,
  nodePercent,
  nodeSize,
  symbol,
}: AssetPercentTextProps) => {
  const theme = useTheme()
  const color = theme.palette.common.white
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const fontSize = (textScale(nodeSize) * (smallScreen ? 0.75 : 1)).toFixed(2)
  const isNarrow = nodeHeight > nodeWidth
  const margin = Math.floor(Math.min(8, (isNarrow ? nodeWidth : nodeHeight) / 6))

  return (
    <g
      transform={(isNarrow && margin < 6)
        ? `rotate(270) translate(-${nodeHeight - 8} ${nodeWidth - margin})`
        : `translate(8 ${nodeHeight - margin})`
      }
    >
      <text
        fill={color}
        fontSize={`${fontSize}em`}
      >
        <tspan
          x={0}
          fontWeight={300}
        >
          {percentFormatter.format(nodePercent)}
        </tspan>
        <tspan
          x={0}
          dy='-.75em'
          fontSize='1.25em'
          fontWeight='bold'
        >
          {symbol}
        </tspan>
      </text>
    </g>
  )
}

type BaseGraphProps = {
  totalQuoteAmount: number
  data: PortfolioCompositionData[]
  width: number
  height: number
}

export const BaseGraph = ({
  totalQuoteAmount,
  data,
  width,
  height,
}: BaseGraphProps) => {
  const theme = useTheme()

  if (width < 10) {
    return null
  }

  const margin = 4

  const amountScale = buildAmountScale(data)

  const stratifiedData = stratify<PortfolioCompositionData>()
    .id((d) => d.id)
    .parentId((d) => d.parent)(data)
    .sum((d) => d.parent === null ? 0 : amountScale(d.size || 0))

  const root = hierarchy(stratifiedData)
    .sort((a, b) => (b.value || 0) - (a.value || 0))

  const colorScale = buildColorScale(theme, root.children?.length || 1)

  return (
    <svg
      height={height}
      width={width}
      style={{ boxShadow: theme.shadows[8] }}
    >
      <rect
        height={height}
        width={width}
        fill={theme.palette.primary.light}
      />
      <Treemap<typeof stratifiedData>
        root={root}
        size={[width, height]}
        tile={tile}
        round
      >
        {(treemap) => (treemap
          .descendants()
          .map((node, i) => {
            if (node.depth === 0) {
              return null
            }

            const nodeWidth = node.x1 - node.x0 - margin
            const nodeHeight = node.y1 - node.y0 - margin
            const nodeData = node.data.data
            const nodePercent = (nodeData.size || 0) / totalQuoteAmount

            return (
              <Group
                key={i}
                left={node.x0 + margin / 2}
                top={node.y0 + margin / 2}
                width={nodeWidth}
                height={nodeHeight}
              >
                <rect
                  width={nodeWidth}
                  height={nodeHeight}
                  fill={colorScale(i)}
                />
                <AssetPercentText
                  nodeHeight={nodeHeight}
                  nodeWidth={nodeWidth}
                  nodePercent={nodePercent}
                  nodeSize={node.value || 0}
                  symbol={getSymbolDisplay(nodeData.id)}
                />
              </Group>
            )
          })
        )}
      </Treemap>
    </svg>
  )
}

export const CompositionGraph = ({
  totalQuoteAmount,
  data,
}: Omit<BaseGraphProps, 'width' | 'height'>) => (
  (data.length > 1) ? (
    <ParentSize>
      {({ width, height }) => (
        <BaseGraph
          totalQuoteAmount={totalQuoteAmount}
          data={data}
          width={width}
          height={height}
        />
      )}
    </ParentSize>
  ) : (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100%'
    >
      <CircularProgress />
    </Box>
  )
)
