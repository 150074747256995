import * as React from 'react'

import { Button, Divider, InputAdornment, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import * as Yup from 'yup'

import { AssetPicker, allowedFrom, allowedTo } from './crypto_picker'
import { ButtonContainer, ButtonsContainer, Currency, ErrorDisplay } from '../../components'
import { isValidAssetAmount } from '../../services'

import type { FormValues, TotalAmounts } from './types'
import type { CreateSwapQuoteVars, MarketAsset, SwapConfig } from '../../queries'
import type { FormikProps } from 'formik'

const initialValues = (createSwapQuoteVars?: CreateSwapQuoteVars): FormValues => ({
  fromSymbol: createSwapQuoteVars?.fromSymbol || '',
  fromAmount: createSwapQuoteVars?.fromAmount || 0,
  useMaxAmount: createSwapQuoteVars?.useMaxAmount || false,
  toSymbol: createSwapQuoteVars?.toSymbol || '',
})

const validationSchema = (totalAmounts: TotalAmounts): Yup.SchemaOf<FormValues> => (
  Yup.object().shape({
    fromSymbol: Yup.string()
      .required('Este campo es obligatorio')
      .nullable(),
    fromAmount: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Este campo es obligatorio')
      .positive('Debes ingresar un monto mayor a cero')
      .test(
        'validFormat',
        'Introduce un número con máximo 6 decimales',
        (value, context) => context.parent.useMaxAmount || isValidAssetAmount(value),
      )
      .test(
        'maxAmount',
        'Debes ingresar un monto menor o igual a tu balance',
        (value, context) => {
          if (!value) {
            return false
          }

          const fromSymbol = context.parent.fromSymbol

          if (!fromSymbol) {
            return false
          }

          const totalAmount = totalAmounts[fromSymbol]

          if (!totalAmount) {
            return false
          }

          return value <= totalAmount
        },
      ),
    useMaxAmount: Yup.boolean()
      .required('Este campo es obligatorio'),
    toSymbol: Yup.string()
      .required('Este campo es obligatorio'),
  })
)

type QuoteVarsFormProps = FormikProps<FormValues> & {
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
  swapConfig: SwapConfig
}

const QuoteVarsForm = ({
  isSubmitting,
  isValid,
  setFieldTouched,
  setFieldValue,
  status,
  submitForm,
  values,
  marketAssets,
  totalAmounts,
  swapConfig,
}: QuoteVarsFormProps) => {
  const { fromSymbol, useMaxAmount } = values

  const onFromSymbolChange = () => {
    setFieldValue('fromAmount', 0)
    setFieldTouched('fromAmount', false, false)
    setFieldValue('useMaxAmount', false)
    setFieldTouched('useMaxAmount', false, false)
    setFieldValue('toSymbol', '')
    setFieldTouched('toSymbol', false, false)
  }

  const onUseMaxAmountChange = () => {
    if (!fromSymbol) {
      return
    }

    const newUseMaxAmount = !useMaxAmount
    setFieldValue('useMaxAmount', newUseMaxAmount)
    setFieldValue('fromAmount', newUseMaxAmount ? totalAmounts[fromSymbol] : 0)
    setFieldTouched('fromAmount', false, false)
  }

  return (
    <Form
      onSubmit={(event) => {
        event?.preventDefault()
        submitForm()
      }}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Divider sx={{ my: 1 }}>De</Divider>
      <AssetPicker
        name='fromSymbol'
        allowedAssets={allowedFrom(marketAssets, swapConfig, totalAmounts)}
        disabled={false}
        onChange={onFromSymbolChange}
      />
      <Field
        required
        name='fromAmount'
        type='number'
        label='Monto a convertir'
        component={TextField}
        disabled={!fromSymbol || useMaxAmount}
        inputProps={{
          autoComplete: 'off',
          inputMode: 'numeric',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'><small>{fromSymbol}</small></InputAdornment>
          ),
          onClick: (event: React.MouseEvent<HTMLInputElement>) => {
            event.currentTarget.querySelector('input')?.select()
          },
        }}
        sx={{
          flexGrow: 1,
          my: 2,
        }}
      />
      <Field
        required
        component={CheckboxWithLabel}
        disabled={!fromSymbol}
        onChange={onUseMaxAmountChange}
        type='checkbox'
        name='useMaxAmount'
        Label={{
          sx: { mt: -1, mb: 1 },
          label: (
            <React.Fragment>
              Usar el máximo disponible (aprox.&nbsp;
              <Currency
                currency={fromSymbol}
                value={totalAmounts[fromSymbol]}
              />)
            </React.Fragment>
          ),
        }}
      />
      <Divider sx={{ my: 1 }}>A</Divider>
      <AssetPicker
        name='toSymbol'
        allowedAssets={allowedTo(marketAssets, swapConfig, fromSymbol)}
        disabled={!fromSymbol}
      />
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 3 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting || !isValid}
            onClick={submitForm}
            variant='contained'
            type='submit'
          >
            Continuar
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type QuoteVarsStepProps = {
  marketAssets: MarketAsset[]
  totalAmounts: TotalAmounts
  swapConfig: SwapConfig
  createSwapQuoteVars?: CreateSwapQuoteVars
  handleNext: (vars: CreateSwapQuoteVars) => void
}

export const QuoteVarsStep = ({
  marketAssets,
  totalAmounts,
  swapConfig,
  createSwapQuoteVars,
  handleNext,
}: QuoteVarsStepProps) => (
  <React.Fragment>
    <Typography
      variant='h6'
      component='span'
      textAlign='center'
      gutterBottom
    >
      Convertir criptomonedas
    </Typography>
    <Formik
      initialValues={initialValues(createSwapQuoteVars)}
      validationSchema={validationSchema(totalAmounts)}
      onSubmit={(values) => handleNext(values)}
    >
      {(props) => (
        <QuoteVarsForm
          marketAssets={marketAssets}
          totalAmounts={totalAmounts}
          swapConfig={swapConfig}
          {...props}
        />
      )}
    </Formik>
  </React.Fragment>
)
