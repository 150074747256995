import * as React from 'react'

import { ArrowBack } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Link } from '@reach/router'

import { ButtonContainer } from '../components'

import type { ButtonContainerProps } from '../components'

export type BackButtonProps = {
  text: string
  containerProps?: ButtonContainerProps
}

export const BackButton = ({
  text,
  containerProps,
}: BackButtonProps) => (
  <ButtonContainer {...containerProps}>
    <Button
      startIcon={<ArrowBack />}
      component={Link}
      to='..'
      sx={{
        pl: 0,
        py: 0.5,
        color: 'text.secondary',
        ':hover': {
          backgroundColor: 'initial',
        },
      }}
    >
      {text}
    </Button>
  </ButtonContainer>
)
