import * as React from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { ButtonContainer } from '../components'

import type { ButtonContainerProps } from '../components'

export type RangeLabel = {
  long: string
  short: string
}

export const rangeLabels: { [range: string]: RangeLabel } = {
  '24h': { long: 'Últimas 24 horas', short: '24H' },
  '7d': { long: 'Últimos 7 días', short: '7D' },
  '30d': { long: 'Últimos 30 días', short: '30D' },
  '1y': { long: 'Último Año', short: '1Y' },
  'all': { long: 'Historial Completo', short: 'TODO' },
}

export type RangeButtonsProps<Range> = {
  disabled?: boolean
  keys: Range[]
  range: Range
  setRange: (value: Range) => void
  containerProps?: ButtonContainerProps
}

export const RangeButtons = <Range extends string>({
  disabled,
  keys,
  range,
  setRange,
  containerProps,
}: RangeButtonsProps<Range>) => {
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newRange: Range | undefined,
  ) => {
    newRange && setRange(newRange)
  }

  return (
    <ButtonContainer {...containerProps}>
      <ToggleButtonGroup
        disabled={disabled}
        aria-label='Rango de fechas'
        color='primary'
        value={range}
        exclusive
        onChange={handleChange}
      >
        {keys.map((key) => (
          <ToggleButton
            key={key}
            value={key}
            aria-label={rangeLabels[key].long}
            sx={{
              py: 0.5,
              px: 1.5,
              '&.Mui-selected': {
                color: 'text.primary',
              },
            }}
          >
            {rangeLabels[key].short}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ButtonContainer>
  )
}
