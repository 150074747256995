import * as React from 'react'

import { Box, Container, Stack, useTheme } from '@mui/material'
import { CountUp } from 'countup.js'

import { Slider } from './slider'
import { Title } from '../seo_typography'

import type { SliderProps } from './slider'

const SITE_NAME = process.env.GATSBY_SITE_NAME

type TestimonialsSectionProps = SliderProps & {
  counterStart: number
  counterLimit: number
}

export const TestimonialsSection  = ({
  counterStart,
  counterLimit,
  testimonials,
}: TestimonialsSectionProps) => {
  const ref = React.useRef<HTMLHeadingElement | null>(null)

  React.useEffect(() => {
    const currentRef = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && currentRef) {
          const countUpOptions = { startVal: counterStart, duration: 180, separator: '.' }
          new CountUp(currentRef, counterLimit, countUpOptions).start()
          observer.disconnect()
        }
      },
      { threshold: 0.5 },
    )

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => observer.disconnect()
  }, [counterLimit, counterStart])

  const palette = useTheme().palette
  const bgColor = (palette.mode === 'light') ? palette.primary.dark : palette.common.black

  return (
    <Stack
      spacing={3}
      sx={{ py: 8 }}
    >
      <Title
        colorful
        textAlign='center'
      >
        Únete a las <span ref={ref}>{counterStart}</span> personas que usan {SITE_NAME}
      </Title>
      <Box
        sx={(theme) => ({
          py: 5,
          width: '100%',
          background: bgColor,
          boxShadow: theme.shadows[12],
          borderTop: 1,
          borderColor: 'hsl(var(--color-shadowColor) / 0.72)',
        })}
      >
        <Container
          disableGutters
          maxWidth='lg'
          sx={(theme) => ({
            position: 'relative',
            ':after': {
              content: '""',
              position: 'absolute',
              zIndex: 1,
              bottom: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              backgroundImage: `linear-gradient(90deg, ${bgColor} 0%,
                                                       transparent 0.4%,
                                                       transparent 99.6%,
                                                       ${bgColor} 100%)`,
              display: 'none',
              [theme.breakpoints.up('lg')]: {
                display: 'block',
              },
            },
          })}
        >
          <Slider testimonials={testimonials} />
        </Container>
      </Box>
    </Stack>
  )
}

export type { Testimonial } from './slider'
