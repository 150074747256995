import * as React from 'react'

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import { percentFormatter } from '../services'

export type PercentVariationProps = {
  value: number
}

export const PercentVariation = ({
  value,
}: PercentVariationProps) => {
  const palette = useTheme().palette
  const color = value === 0
    ? palette.text.primary
    : (value > 0 ? palette.success.main : palette.error.main)
  const arrow = value === 0
    ? null
    : (value > 0 ? <ArrowDropUp /> : <ArrowDropDown />)
  const style = {
    color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
  return (
    <span style={style}>
      {arrow}
      {percentFormatter.format(Math.abs(value) / 100.0)}
    </span>
  )
}
