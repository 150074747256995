import * as React from 'react'

import { BlurOn, Error } from '@mui/icons-material'
import { List, ListItem, ListItemText, Paper, Skeleton } from '@mui/material'

import { Currency } from '../../components'

import type { CreateSwapQuoteVars, SwapQuote } from '../../queries'

type QuotePreviewProps = {
  createSwapQuoteVars?: CreateSwapQuoteVars
  quote?: SwapQuote
  errorMsg?: React.ReactNode
}

export const QuotePreview = ({
  createSwapQuoteVars,
  quote,
  errorMsg,
}: QuotePreviewProps) => (
  <List disablePadding>
    <ListItem
      divider
      disableGutters
    >
      <ListItemText primary='Utilizarás' />
      <ListItemText
        primary={(typeof createSwapQuoteVars === 'undefined') ? (
          errorMsg ? '--' : <Skeleton />
        ) : (
          <Currency
            currency={createSwapQuoteVars.fromSymbol}
            value={createSwapQuoteVars.fromAmount}
          />
        )}
        primaryTypographyProps={{ textAlign: 'right' }}
      />
    </ListItem>
    <ListItem
      divider
      disableGutters
    >
      <ListItemText primary='Obtendrás' />
      <ListItemText
        primary={(typeof createSwapQuoteVars === 'undefined' || typeof quote === 'undefined') ? (
          errorMsg ? '--' : <Skeleton />
        ) : (
          <Currency
            currency={createSwapQuoteVars.toSymbol}
            value={quote.toAmount}
          />
        )}
        primaryTypographyProps={{ textAlign: 'right' }}
      />
    </ListItem>
    <ListItem disableGutters>
      <ListItemText primary='Tasa de conversión' />
      <ListItemText
        primary={(typeof createSwapQuoteVars === 'undefined' || typeof quote === 'undefined') ? (
          errorMsg ? '--' : <Skeleton />
        ) : (
          <Currency
            currency={`${createSwapQuoteVars.fromSymbol} ➡️ ${createSwapQuoteVars.toSymbol}`}
            value={quote.toAmount / createSwapQuoteVars.fromAmount}
          />
        )}
        primaryTypographyProps={{ textAlign: 'right' }}
      />
    </ListItem>
    <ListItem disableGutters>
      <Paper
        variant='outlined'
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'action.hover',
          gap: 2,
          p: 2,
        }}
      >
        {(typeof errorMsg === 'undefined') ? (
          (typeof quote === 'undefined') ? (
            'Obteniendo precio...'
          ) : (
            <React.Fragment>
              <BlurOn />
              Estos valores son aproximados
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <Error />
            {errorMsg}
          </React.Fragment>
        )}
      </Paper>
    </ListItem>
  </List>
)
