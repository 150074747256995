import { gql } from '@apollo/client'

export type PurchaseMethod = 'CREDIT_CARD' | 'BANK_TRANSFER'

export type PurchaseConfig = {
  userFiatCurrency?: string
  purchaseMethods: PurchaseMethod[]
}

export type PurchaseConfigVars = Record<string, never>

export type PurchaseConfigData = {
  purchaseConfig: PurchaseConfig
}

export const PURCHASE_CONFIG_QUERY = gql`
  query PurchaseConfig {
    purchaseConfig {
      userFiatCurrency
      purchaseMethods
    }
  }
`

export type PurchaseQuote = {
  id: string
  providerId: string
  cryptoAmount: number
  remainingSeconds: number
}

export type CreatePurchaseQuoteVars = {
  fiatAmount: number
  purchaseMethod: PurchaseMethod
}

export type CreatePurchaseQuoteData = {
  createPurchaseQuote: PurchaseQuote
}

export const CREATE_PURCHASE_QUOTE_MUTATION = gql`
  mutation CreatePurchaseQuote(
    $fiatAmount: Float!
    $purchaseMethod: String!
  ) {
    createPurchaseQuote(
      input: {
        fiatAmount: $fiatAmount
        purchaseMethod: $purchaseMethod
      }
    ) {
      id
      providerId
      cryptoAmount
      remainingSeconds
    }
  }
`

export type Payment = {
  providerId: string
  providerSpecificKeys: string[]
  providerSpecificValues: string
}

export type CreatePaymentVars = {
  providerId: string
  quoteId: string
}

export type CreatePaymentData = {
  createPayment: Payment
}

export const CREATE_PAYMENT_MUTATION = gql`
  mutation CreatePayment(
    $providerId: String!
    $quoteId: String!
  ) {
    createPayment(
      input: {
        providerId: $providerId
        quoteId: $quoteId
      }
    ) {
      providerId
      providerSpecificKeys
      providerSpecificValues
    }
  }
`
