import * as React from 'react'

import { AccountBalance, CheckCircle, History } from '@mui/icons-material'
import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { Link } from '@reach/router'

import { ButtonContainer, ButtonsContainer, Currency } from '../../components'

import type { MarketAsset } from '../../queries'

type ConfirmationStepProps = {
  marketAsset: MarketAsset | null
  blockchain: string
  address: string
  amount: number
}

export const ConfirmationStep = ({
  marketAsset,
  blockchain,
  address,
  amount,
}: ConfirmationStepProps) => (
  <Stack height='100%'>
    <List
      disablePadding
      aria-label='Retiro completado'
    >
      <ListItem
        divider
        disableGutters
        sx={{ pt: 0, mb: 1 }}
      >
        <ListItemText
          primary='Retiro completado'
          primaryTypographyProps={{ variant: 'h6', textAlign: 'center' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Cantidad' />
        <ListItemText
          primary={(
            <Currency
              currency={marketAsset?.symbol || '-'}
              value={amount}
            />
          )}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Comisión de red' />
        <ListItemText
          primary='Incluida'
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Red' />
        <ListItemText
          primary={blockchain}
          primaryTypographyProps={{ textAlign: 'right' }}
        />
      </ListItem>
      <ListItem
        divider
        disableGutters
      >
        <ListItemText primary='Dirección' />
        <ListItemText
          primary={address}
          primaryTypographyProps={{ fontFamily: 'monospace', fontSize: '.95em', textAlign: 'right' }}
          sx={{ overflowWrap: 'anywhere', maxWidth: '60%' }}
        />
      </ListItem>
    </List>
    <Stack
      spacing={1}
      direction='row'
      alignItems='center'
      justifyContent='center'
      sx={{ mt: 3, mb: 1 }}
    >
      <Typography
        variant='h6'
        component='span'
      >
        Transacción iniciada
      </Typography>
      <CheckCircle color='success' />
    </Stack>
    <Typography textAlign='center'>
      Puedes ver el estado de esta transacción en Historial.
    </Typography>
    <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../../history'
          startIcon={<History />}
          variant='outlined'
        >
          Ir a Historial
        </Button>
      </ButtonContainer>
      <ButtonContainer xs={6}>
        <Button
          fullWidth
          component={Link}
          to='../../portfolio'
          startIcon={<AccountBalance />}
          variant='contained'
        >
          Ir a Portafolio
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Stack>
)
