import * as React from 'react'

import { BlurOn, Error } from '@mui/icons-material'
import { List, ListItem, ListItemText, Paper, Skeleton, Typography } from '@mui/material'

import { Currency } from '../../components'

import type { CreatePurchaseQuoteVars, PurchaseConfig, PurchaseQuote } from '../../queries'

type QuotePreviewProps = {
  purchaseConfig?: PurchaseConfig
  createPurchaseQuoteVars?: CreatePurchaseQuoteVars
  purchaseQuote?: PurchaseQuote
  errorMsg?: React.ReactNode
}

export const QuotePreview = ({
  purchaseConfig,
  createPurchaseQuoteVars,
  purchaseQuote,
  errorMsg,
}: QuotePreviewProps) => (
  <List disablePadding>
    <ListItem
      divider
      disableGutters
    >
      <ListItemText primary='Utilizarás' />
      <ListItemText
        primary={(typeof purchaseConfig === 'undefined' || typeof createPurchaseQuoteVars === 'undefined') ? (
          errorMsg ? '--' : <Skeleton />
        ) : (
          <Currency
            currency={purchaseConfig.userFiatCurrency || ''}
            value={createPurchaseQuoteVars.fiatAmount}
          />
        )}
        primaryTypographyProps={{ textAlign: 'right' }}
      />
    </ListItem>
    <ListItem
      divider
      disableGutters
    >
      <ListItemText primary='Obtendrás' />
      <ListItemText
        primary={(typeof purchaseQuote === 'undefined') ? (
          errorMsg ? '--' : <Skeleton />
        ) : (
          <Currency
            currency='USD'
            value={purchaseQuote.cryptoAmount}
          />
        )}
        primaryTypographyProps={{ textAlign: 'right' }}
      />
    </ListItem>
    <ListItem disableGutters>
      <ListItemText primary='Precio dólar' />
      <ListItemText
        primary={(typeof purchaseQuote === 'undefined' || typeof createPurchaseQuoteVars === 'undefined') ? (
          errorMsg ? '--' : <Skeleton />
        ) : (
          <Currency
            currency='USD'
            value={createPurchaseQuoteVars.fiatAmount / purchaseQuote.cryptoAmount}
          />
        )}
        primaryTypographyProps={{ textAlign: 'right' }}
      />
    </ListItem>
    <ListItem disableGutters>
      <Paper
        variant='outlined'
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'action.hover',
          gap: 2,
          p: 2,
        }}
      >
        {(typeof errorMsg === 'undefined') ? (
          (typeof purchaseQuote === 'undefined') ? (
            'Obteniendo precio...'
          ) : (
            <React.Fragment>
              <BlurOn />
              Estos valores son aproximados
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <Error />
            <Typography>{errorMsg}</Typography>
          </React.Fragment>
        )}
      </Paper>
    </ListItem>
  </List>
)
