import { gql } from '@apollo/client'

export type UserNotificationType = 'PURCHASE' | 'SWAP' | 'WITHDRAW_BANK'

export const translateUserNotificationType = (notificationType?: UserNotificationType) => {
  switch (notificationType) {
  case 'PURCHASE': return 'Compra'
  case 'SWAP': return 'Conversión'
  case 'WITHDRAW_BANK': return 'Retiro Bancario'
  default: return ''
  }
}

export const translateUserNotificationStatus = (resolvedAt?: string | null) => (
  resolvedAt ? 'Resuelta ✅' : 'Pendiente ⚠️'
)

export type UserNotification = {
  id: string
  content: string
  createdAt: string
  notificationType: UserNotificationType
  resolvedAt: string | null
}

export const USER_NOTIFICATION_FIELDS = gql`
  fragment UserNotificationFields on UserNotification {
    id
    content
    createdAt
    notificationType
    resolvedAt
  }
`

export type UserNotificationsVars = Record<string, never>

export type UserNotificationsData = {
  userNotifications: UserNotification[]
}

export const USER_NOTIFICATIONS_QUERY = gql`
  ${USER_NOTIFICATION_FIELDS}
  query UserNotifications {
    userNotifications {
      ...UserNotificationFields
    }
  }
`
