import React from 'react'

import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import BaseSlider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Image } from '../image'

import type { Settings } from 'react-slick'

export type Testimonial = {
  name: string
  title: string
  quote: string
  imageSrc: string
}

type CardProps = {
  testimonial: Testimonial
  halfMargin: boolean
}

const Card = ({ testimonial, halfMargin }: CardProps) => (
  <Paper
    elevation={8}
    sx={{
      borderRadius: 3,
      minHeight: '170px',
      ml: halfMargin ? '-48%' : '2%',
      mr: halfMargin ? '52%' : '2%',
      my: 2,
      p: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      gap: 2,
    }}
  >
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
    >
      <Image
        src={testimonial.imageSrc}
        alt={testimonial.name}
        width='50px'
        height='50px'
        style={{ borderRadius: '50%' }}
      />
      <Box>
        <Typography variant='body1'>
          {testimonial.name}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
        >
          {testimonial.title}
        </Typography>
      </Box>
    </Stack>
    <Typography>
      {testimonial.quote}
    </Typography>
  </Paper>
)

export type SliderProps = {
  testimonials: Testimonial[]
}

export const Slider: React.FC<SliderProps> = ({ testimonials }) => {
  const settings: Settings = {
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 300,
    rows: 2,
    slidesToScroll: .5,
  }

  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  if (largeScreen) {
    settings['slidesToShow'] = 3
  } else if (smallScreen) {
    settings['slidesToShow'] = 2
  } else {
    settings['slidesToShow'] = 1
  }

  return (
    <BaseSlider {...settings}>
      {testimonials.map((testimonial, index) => (
        <Card
          key={index}
          halfMargin={!!(index % 2)}
          testimonial={testimonial}
        />
      ))}
    </BaseSlider>
  )
}
