import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button, List, ListItem, ListItemText, Typography } from '@mui/material'

import { AuthCodeForm } from './auth_code_form'
import { BankAccountDisplay } from './bank_account_display'
import { SaleQuoteDisplay } from './sale_quote_display'
import { ButtonContainer, ButtonsContainer, ErrorDisplay } from '../../components'
import { CREATE_SALE_QUOTE_MUTATION } from '../../queries'
import { translateError } from '../../services'

import type {
  BankAccount,
  BankConfig,
  CreateSaleQuoteData,
  CreateSaleQuoteVars,
  MarketAsset,
  SaleQuote,
} from '../../queries'

type SaleQuoteRenewerProps = {
  bankAccount: BankAccount | null
  cryptoAmount: number
  saleQuote?: SaleQuote
  setSaleQuote: (saleQuote?: SaleQuote) => void
  setQuoteRemainingTime: (quoteRemainingTime?: number) => void
  handleBack: () => void
}

const SaleQuoteRenewer = ({
  bankAccount,
  cryptoAmount,
  saleQuote,
  setSaleQuote,
  setQuoteRemainingTime,
  handleBack,
}: SaleQuoteRenewerProps) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<React.ReactNode>()

  const [createSaleQuote] =
    useMutation<CreateSaleQuoteData, CreateSaleQuoteVars>(CREATE_SALE_QUOTE_MUTATION, {
      errorPolicy: 'all',
      variables: {
        bankAccountId: bankAccount?.id || '',
        cryptoAmount,
      },
    })

  const handleCreateSaleQuote = React.useCallback(async () => {
    setSubmitting(true)
    setErrorMsg(undefined)
    const response = await createSaleQuote()
    setSubmitting(false)

    if (response.errors && response.errors.length > 0) {
      setErrorMsg(translateError(response))
    } else {
      const newQuote = response.data?.createSaleQuote
      setSaleQuote(newQuote)
      setQuoteRemainingTime(newQuote?.remainingSeconds)
    }
  }, [createSaleQuote, setQuoteRemainingTime, setSaleQuote])

  React.useEffect(() => {
    !saleQuote && handleCreateSaleQuote()
  }, [handleCreateSaleQuote, saleQuote])

  return (
    <React.Fragment>
      <Typography
        align='center'
        mt={2}
        mb={1}
      >
        {(saleQuote) ? (
          <React.Fragment>
            La tasa de conversión expiró (presiona&nbsp;para&nbsp;actualizar)
          </React.Fragment>
        ) : (
          'Actualizando tasa de conversión...'
        )}
      </Typography>
      <ErrorDisplay
        errorMsg={errorMsg}
        mt={2}
      />
      <ButtonsContainer sx={{ alignItems: 'flex-end', mt: 2 }}>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={submitting}
            onClick={handleBack}
            variant='outlined'
          >
            Regresar
          </Button>
        </ButtonContainer>
        <ButtonContainer xs={6}>
          <Button
            fullWidth
            disabled={submitting}
            onClick={handleCreateSaleQuote}
            variant='contained'
          >
            {submitting ? 'Actualizando' : 'Actualizar'}
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </React.Fragment>
  )
}

type AuthCodeStepProps = {
  bankConfig: BankConfig
  cryptoAmount: number
  bankAccount: BankAccount | null
  marketAsset: MarketAsset | null
  handleBack: () => void
  handleNext: (saleQuote: SaleQuote) => void
}

export const AuthCodeStep = ({
  bankConfig,
  cryptoAmount,
  bankAccount,
  marketAsset,
  handleBack,
  handleNext,
}: AuthCodeStepProps) => {
  const [saleQuote, setSaleQuote] = React.useState<SaleQuote>()
  const [quoteRemainingTime, setQuoteRemainingTime] = React.useState<number>()

  React.useEffect(() => {
    const updateRemainingTime = () => {
      setQuoteRemainingTime((remainingTime) => {
        if (typeof remainingTime === 'undefined') {
          return undefined
        }

        return Math.max(remainingTime - 1, 0)
      })
    }
    const interval = setInterval(updateRemainingTime, 1000)
    return () => clearInterval(interval)
  }, [saleQuote])

  return (
    <React.Fragment>
      <List
        disablePadding
        aria-label='Autoriza tu retiro'
      >
        <ListItem
          divider
          disableGutters
          sx={{ pt: 0, mb: 1 }}
        >
          <ListItemText
            primary='Autoriza tu retiro'
            primaryTypographyProps={{ variant: 'h6', textAlign: 'center' }}
          />
        </ListItem>
        <SaleQuoteDisplay
          bankConfig={bankConfig}
          saleQuote={saleQuote}
          marketAsset={marketAsset}
          cryptoAmount={cryptoAmount}
          quoteRemainingTime={quoteRemainingTime}
        />
        <BankAccountDisplay
          bankConfig={bankConfig}
          bankAccount={bankAccount}
        />
      </List>
      {(!saleQuote || !quoteRemainingTime) ? (
        <SaleQuoteRenewer
          bankAccount={bankAccount}
          cryptoAmount={cryptoAmount}
          saleQuote={saleQuote}
          setSaleQuote={setSaleQuote}
          setQuoteRemainingTime={setQuoteRemainingTime}
          handleBack={handleBack}
        />
      ) : (
        <AuthCodeForm
          cryptoAmount={cryptoAmount}
          bankAccount={bankAccount}
          saleQuote={saleQuote}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      )}
    </React.Fragment>
  )
}
