import * as React from 'react'

import { ChevronRight } from '@mui/icons-material'
import { Button, Skeleton } from '@mui/material'

import type { MarketAsset } from '../../queries'

type DetailsButtonProps = {
  marketAsset: MarketAsset | null
}

export const DetailsButton = ({
  marketAsset,
}: DetailsButtonProps) => (
  <Button
    sx={(theme) => ({
      p: 0,
      flexGrow: 1,
      justifyContent: 'flex-end',
      fontSize: theme.typography.body1.fontSize,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& > .MuiButton-endIcon': {
        ml: 0,
      },
    })}
    endIcon={<ChevronRight />}
  >
    {(marketAsset !== null) ? (
      marketAsset.name
    ) : (
      <Skeleton width={80} />
    )}
  </Button>
)
