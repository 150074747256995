import * as React from 'react'

import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { AxisBottom } from '@visx/axis'
import { curveLinear } from '@visx/curve'
import { localPoint } from '@visx/event'
import { LinearGradient } from '@visx/gradient'
import { ParentSize } from '@visx/responsive'
import { scaleLinear, scaleTime } from '@visx/scale'
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape'
import { TooltipWithBounds, defaultStyles, withTooltip } from '@visx/tooltip'
import { bisector } from 'd3-array'
import { timeFormat } from 'd3-time-format'

import { Currency } from '../components'

const verticalPadding = 4
const axisHeight = 20

const formatDate = timeFormat('%Y-%m-%d %H:%M')
const formatPrice = (currency: string, value: number, svg: boolean) => (
  <Currency
    currency={currency}
    value={value}
    svg={svg}
  />
)

export type PriceGraphPoint = {
  timestamp: number
  value: number
}

const getX = (p: PriceGraphPoint) => new Date(p.timestamp)
const getY = (p: PriceGraphPoint) => p.value
const bisectDate = bisector((p: PriceGraphPoint) => getX(p)).left

type BaseGraphProps = {
  currency: string
  isStableCoin?: boolean
  data: PriceGraphPoint[]
  ath: number
  atl: number
  width: number
  height: number
}

const BaseGraph = withTooltip<BaseGraphProps, PriceGraphPoint>(({
  currency,
  isStableCoin,
  data,
  ath,
  atl,
  width,
  height,
  showTooltip,
  hideTooltip,
  tooltipData,
  tooltipTop = 0,
  tooltipLeft = 0,
}) => {
  const theme = useTheme()
  const palette = theme.palette
  const accentColor = palette.mode === 'light' ? palette.primary.dark : palette.primary.light
  const axisColor = palette.text.secondary
  const lightOpacity = palette.action.disabledOpacity
  const tooltipBackgroundColor = palette.background.default
  const tooltipColor = palette.text.primary
  const priceLevelFontSize = theme.typography.body2.fontSize
  const axisFontSize = theme.typography.caption.fontSize

  if (data.length === 1) {
    data.push({
      value: data[0].value,
      timestamp: new Date().getTime(),
    })
  }

  const firstPoint = data[0]
  const lastPoint = data[data.length - 1]
  const safeAth = ath > 0 ? ath : 1
  const safeAtl = (ath === atl) ? 0 : atl

  const minGraphY = isStableCoin ? safeAtl * 0.999 : safeAtl
  const maxGraphY = isStableCoin ? safeAth * 1.001 : safeAth

  const xAxisScale = React.useMemo(
    () =>
      scaleTime({
        domain: [getX(firstPoint), getX(lastPoint)],
        range: [0, width],
      }),
    [firstPoint, lastPoint, width],
  )
  const yAxisScale = React.useMemo(
    () =>
      scaleLinear({
        domain: [minGraphY, maxGraphY],
        range: [height - 2 * (axisHeight + verticalPadding), verticalPadding + axisHeight],
      }),
    [minGraphY, maxGraphY, height],
  )

  const handleTooltip = React.useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 }
      const x0 = xAxisScale.invert(x)
      const index = bisectDate(data, x0, 1)
      const d0 = data[index - 1]
      const d1 = data[index]
      let d = d0
      if (d1 && getX(d1)) {
        d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf()
          ? d1
          : d0
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: xAxisScale(getX(d)),
        tooltipTop: yAxisScale(getY(d)),
      })
    },
    [data, showTooltip, yAxisScale, xAxisScale],
  )

  const priceLevel = (price: number, over: boolean) => (
    <g>
      <LinePath
        data={[
          { timestamp: firstPoint.timestamp, value: price },
          { timestamp: lastPoint.timestamp, value: price },
        ]}
        x={(d) => xAxisScale(getX(d)) ?? 0}
        y={(d) => yAxisScale(getY(d)) ?? 0}
        stroke={accentColor}
        strokeWidth={1}
        strokeDasharray='4,4'
        strokeOpacity={lightOpacity}
      />
      <text
        fill={accentColor}
        y={yAxisScale(price)}
        dy={over ? '-.5em' : '1.2em'}
        dx={10}
        fontSize={priceLevelFontSize}
      >
        {formatPrice(currency, price, true)}
      </text>
    </g>
  )

  const strokeScale = Math.max(Math.min(0.3 * width / data.length, 1), 0.5)

  return (
    <div style={{ touchAction: 'pan-y' }}>
      <svg
        width={width}
        height={height}
      >
        <LinearGradient
          id='area-gradient'
          from={palette.primary.main}
          to={palette.primary.main}
          fromOpacity={0.8 * lightOpacity}
          toOpacity={0.2 * lightOpacity}
        />
        <AreaClosed<PriceGraphPoint>
          data={data}
          x={(d) => xAxisScale(getX(d)) ?? 0}
          y={(d) => yAxisScale(getY(d)) ?? 0}
          yScale={yAxisScale}
          strokeWidth={2}
          stroke='transparent'
          fill='url(#area-gradient)'
          curve={curveLinear}
        />
        <LinePath
          data={data}
          x={(d) => xAxisScale(getX(d)) ?? 0}
          y={(d) => yAxisScale(getY(d)) ?? 0}
          stroke={accentColor}
          strokeOpacity={0.8 * lightOpacity}
          strokeWidth={(tooltipData ? 4 : 2.5) * strokeScale}
          style={{
            transition: 'all .3s ease-out',
            mixBlendMode: palette.mode === 'light' ? 'darken' : 'color-dodge',
          }}
        />
        <Bar
          x={0}
          y={0}
          width={width}
          height={height}
          fill='transparent'
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onTouchEnd={hideTooltip}
          onMouseLeave={hideTooltip}
        />
        {(ath === safeAth) && priceLevel(safeAth, true)}
        {priceLevel(safeAtl, false)}
        <AxisBottom
          scale={xAxisScale}
          top={height - axisHeight}
          numTicks={3}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => ({
            fill: axisColor,
            fontSize: axisFontSize,
            textAnchor: 'middle',
          })}
        />
        {tooltipData && (
          <g>
            {(ath === safeAth) && (
              <Line
                from={{ x: tooltipLeft, y: yAxisScale(safeAth) }}
                to={{ x: tooltipLeft, y: yAxisScale(safeAtl) }}
                stroke={accentColor}
                strokeWidth={2}
                pointerEvents='none'
                strokeDasharray='4,2'
              />
            )}
            <Line
              from={{ x: tooltipLeft, y: yAxisScale(safeAtl) }}
              to={{ x: tooltipLeft, y: height - axisHeight }}
              stroke={axisColor}
              opacity={lightOpacity}
              strokeWidth={2}
              pointerEvents='none'
              strokeDasharray='4,2'
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={6}
              fill={palette.primary.main}
              pointerEvents='none'
              style={{
                mixBlendMode: palette.mode === 'light' ? 'hard-light' : 'screen',
              }}
            />
          </g>
        )}
      </svg>
      {tooltipData && (
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            color: tooltipColor,
            background: tooltipBackgroundColor,
            textAlign: 'center',
            boxShadow: theme.shadows[1],
          }}
        >
          <Typography>
            {formatPrice(currency, getY(tooltipData), false)}
          </Typography>
          <Typography
            variant='caption'
            color='text.secondary'
          >
            {formatDate(getX(tooltipData))}
          </Typography>
        </TooltipWithBounds>
      )}
    </div>
  )
})

export const TickerPricesGraph = ({
  currency,
  isStableCoin,
  data,
  ath,
  atl,
}: Omit<BaseGraphProps, 'width' | 'height'>) => (
  <ParentSize>
    {({ width, height }) => (data.length > 0) ? (
      <BaseGraph
        currency={currency}
        isStableCoin={isStableCoin}
        data={data}
        ath={ath}
        atl={atl}
        width={width}
        height={height}
      />
    ) : (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100%'
      >
        <CircularProgress />
      </Box>
    )}
  </ParentSize>
)
