import * as React from 'react'

import { GoogleOAuthProvider } from '@react-oauth/google'

import { AppContext } from 'shared/contexts'

import type { AppContextType } from 'shared/contexts'

const buildContext = (animatedLogo?: string, defaultOgImage?: string): AppContextType => ({
  animatedLogo: animatedLogo || '',
  appName: process.env.GATSBY_SITE_NAME || '',
  appTitle: process.env.GATSBY_SITE_TITLE || '',
  defaultOgImage: defaultOgImage || '',
  simplexPublicKey: process.env.GATSBY_SIMPLEX_PUBLIC_KEY || '',
  simplexScriptSrc: process.env.GATSBY_SIMPLEX_SCRIPT_SRC || '',
  supportEmail: process.env.GATSBY_SUPPORT_EMAIL || '',
})

type WrapperProps = {
  animatedLogo?: string
  defaultOgImage?: string
  googleOAuthClientId?: string
  children: React.ReactNode
}

export const Wrapper = ({
  animatedLogo,
  defaultOgImage,
  googleOAuthClientId,
  children,
}: WrapperProps) => (
  <GoogleOAuthProvider clientId={googleOAuthClientId || ''}>
    <AppContext.Provider value={buildContext(animatedLogo, defaultOgImage)}>
      {children}
    </AppContext.Provider>
  </GoogleOAuthProvider>
)
