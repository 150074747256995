import * as React from 'react'

import { Stack, Typography } from '@mui/material'

import { Image, SupportMailLink } from '../components'
import { AppContext } from '../contexts'

type NotAvailableProps = {
  message: string
}

export const NotAvailable = ({
  message,
}: NotAvailableProps) => {
  const { animatedLogo, appName } = React.useContext(AppContext)

  return (
    <Stack
      spacing={3}
      alignItems='center'
    >
      <Image
        src={animatedLogo}
        alt={`Logo ${appName} cambiante`}
        width='75%'
        duration={500}
      />
      <Typography
        variant='h5'
        textAlign='center'
      >
        {message}
      </Typography>
      <Typography
        variant='subtitle1'
        textAlign='center'
      >
        Si necesitas ayuda, o tienes cualquier duda al respecto por favor
        contáctanos a través de nuestro correo de soporte:
      </Typography>
      <SupportMailLink />
    </Stack>
  )
}
