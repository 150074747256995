import { timeFormat } from 'd3-time-format'

import {
  BankDeposit,
  BulkPurchase,
  Deposit,
  InterestPayout,
  ReferralPayout,
  Swap,
  USER_OPERATION_LABELS,
  UserOperation,
  Withdrawal,
} from '../queries'

const formatDateTime = timeFormat('%Y-%m-%d %H:%M:%S')
const formatTimestamp = (timestamp: number | string) => formatDateTime(new Date(timestamp))

const getOperationLabel = (__typename: UserOperation['__typename']) => (
  USER_OPERATION_LABELS.find(({ typename }) => typename === __typename)?.label || __typename
)

const HEADER = [
  'Tipo de operación',
  'Fecha de creación',
  'Fecha de cierre',
  'Ingreso: monto',
  'Ingreso: activo',
  'Egreso: monto',
  'Egreso: activo',
].join(',')

const bankDepositRow = (operation: BankDeposit) => (
  [
    getOperationLabel(operation.__typename),
    formatTimestamp(operation.timestamp),
    operation.spentAt ? formatTimestamp(operation.spentAt) : '',
    operation.amount,
    'CLP',
    '',
    '',
  ].join(',')
)

const bulkPurchaseRow = (operation: BulkPurchase) => (
  [
    getOperationLabel(operation.__typename),
    formatTimestamp(operation.timestamp),
    operation.closedAt ? formatTimestamp(operation.closedAt) : '',
    operation.inAmount,
    'CLP',
    operation.outAmount,
    'USDT',
  ].join(',')
)

const depositRow = (operation: Deposit | InterestPayout | ReferralPayout) => (
  [
    getOperationLabel(operation.__typename),
    formatTimestamp(operation.timestamp),
    formatTimestamp(operation.timestamp),
    operation.changedAmount,
    operation.symbol,
    '',
    '',
  ].join(',')
)

const swapRow = (operation: Swap) => (
  [
    getOperationLabel(operation.__typename),
    formatTimestamp(operation.timestamp),
    formatTimestamp(operation.timestamp),
    operation.toChangedAmount,
    operation.toSymbol,
    operation.fromChangedAmount,
    operation.fromSymbol,
  ].join(',')
)

const withdrawalRow = (operation: Withdrawal) => (
  [
    getOperationLabel(operation.__typename),
    formatTimestamp(operation.timestamp),
    formatTimestamp(operation.timestamp),
    '',
    '',
    operation.changedAmount,
    operation.symbol,
  ].join(',')
)

const rows = (operations: UserOperation[]) => (
  operations.sort((a, b) => a.timestamp - b.timestamp)
    .map((operation: UserOperation) => {
      switch (operation.__typename) {
      case 'BankDeposit': return bankDepositRow(operation)
      case 'BulkPurchase': return bulkPurchaseRow(operation)
      case 'Deposit': return depositRow(operation)
      case 'InterestPayout': return depositRow(operation)
      case 'ReferralPayout': return depositRow(operation)
      case 'Swap': return swapRow(operation)
      case 'Withdrawal': return withdrawalRow(operation)
      default: return
      }
    })
    .filter((row?: string) => row)
)

export const operationsToCSV = (operations: UserOperation[]) => {
  const csvString = [HEADER, ...rows(operations)].join('\n')
  return new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
}
