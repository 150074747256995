import * as React from 'react'

import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { navigate } from '@reach/router'

import { ApyDisplay } from './apy_display'
import { AssetBadgeDisplay } from './asset_badge_display'
import { BalanceDisplay } from './balance_display'
import { DetailsButton } from './details_button'
import { EarningsDisplay } from './earnings_display'
import { AppContainer } from '../../components'
import { DomainContext } from '../../contexts'
import { type PortfolioBalance, getSymbol } from '../../services'

import type { MarketAsset } from '../../queries'

type UserAssetDisplayProps = {
  graphUnit: string
  marketAsset: MarketAsset | null
  portfolioBalance: PortfolioBalance | null
  baseApy: number | null
}

export const UserAssetDisplay = ({
  graphUnit,
  marketAsset,
  portfolioBalance,
  baseApy,
}: UserAssetDisplayProps) => {
  const largeScreen = useMediaQuery(useTheme().breakpoints.up('md'))
  const { quoteSymbol, conversionCurrency } = React.useContext(DomainContext)

  const sadMode = portfolioBalance === null || portfolioBalance.totalAmount === 0

  let secondaryCurrency = graphUnit
  if (getSymbol(marketAsset?.symbol) === quoteSymbol) {
    secondaryCurrency = conversionCurrency!
  }

  const price = (secondaryCurrency === conversionCurrency)
    ? (portfolioBalance?.usdPrice || 0)
    : (portfolioBalance?.quotePrice || 0)

  const handleClick = (marketAsset !== null)
    ? () => navigate(`portfolio/${marketAsset.symbol}`)
    : undefined

  return (
    <AppContainer
      onClick={handleClick}
      sx={{ cursor: 'pointer', height: '100%' }}
      lg={6}
      xl={4}
    >
      <Stack
        p={2}
        height='100%'
        spacing={2}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <BalanceDisplay
            sadMode={sadMode}
            marketAsset={marketAsset}
            portfolioBalance={portfolioBalance}
            secondaryCurrency={secondaryCurrency}
            price={price}
          />
          <AssetBadgeDisplay
            sadMode={sadMode}
            marketAsset={marketAsset}
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          height='100%'
          spacing={2}
        >
          {!!baseApy && (
            <React.Fragment>
              <ApyDisplay
                sadMode={sadMode}
                baseApy={baseApy}
              />
              {largeScreen && (
                <EarningsDisplay
                  sadMode={sadMode}
                  marketAsset={marketAsset}
                  portfolioBalance={portfolioBalance}
                />
              )}
            </React.Fragment>
          )}
          <DetailsButton
            marketAsset={marketAsset}
          />
        </Stack>
      </Stack>
    </AppContainer>
  )
}
