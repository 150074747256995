import * as React from 'react'

import { Skeleton } from '@mui/material'

import { AssetBadge } from '..'

import type { MarketAsset } from '../../queries'

type AssetBadgeDisplayProps = {
  sadMode: boolean
  marketAsset: MarketAsset | null
}

export const AssetBadgeDisplay = ({
  sadMode,
  marketAsset,
}: AssetBadgeDisplayProps) => {
  if (marketAsset === null) {
    return (
      <Skeleton
        variant='circular'
        width={48}
        height={48}
      />
    )
  }

  return (
    <AssetBadge
      symbol={marketAsset.symbol}
      height={48}
      style={{
        filter: sadMode ? 'grayscale(100%)' : 'none',
        opacity: sadMode ? 0.5 : 1,
      }}
    />
  )
}
