import * as React from 'react'

import { Autocomplete, MenuItem, TextField as MuiTextField, Stack } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { SelectField } from './select_field'
import { SOCIETY_TYPE_LABELS } from '../queries'
import { SII_ACTIVITIES, findSiiActivity, hasValidRut } from '../services'

import type { BusinessIdentity } from '../queries'

export type BusinessIdentityFormValues = {
  companyName: string
  identificationNumber: string
  siiActivityCode: string
  societyType: string
}

export const getBusinessIdentityInitialValues = (
  data?: BusinessIdentity,
): BusinessIdentityFormValues => ({
  companyName: data?.companyName || '',
  identificationNumber: data?.identificationNumber || '',
  siiActivityCode: data?.siiActivityCode || '',
  societyType: data?.societyType || '',
})

export const businessIdentityValidationSchema: Yup.SchemaOf<BusinessIdentityFormValues> =
  Yup.object({
    companyName: Yup.string()
      .required('Este campo es obligatorio')
      .min(2, 'Debes ingresar al menos dos caracteres')
      .max(64, 'No puede tener más de 64 caracteres'),
    identificationNumber: Yup.string()
      .required('Este campo es obligatorio')
      .test({
        message: 'El rut ingresado no es valido',
        test: (documentNumber) => hasValidRut(documentNumber),
      }),
    siiActivityCode: Yup.string()
      .required('Este campo es obligatorio'),
    societyType: Yup.string()
      .required('Este campo es obligatorio'),
  })

export const BusinessIdentityFields = () => {
  const {
    setFieldTouched,
    setFieldValue,
    values,
  } = useFormikContext<BusinessIdentityFormValues>()

  return (
    <Stack spacing={3}>
      <Field
        required
        name='companyName'
        type='text'
        label='Nombre Empresa'
        component={TextField}
      />
      <SelectField
        name='societyType'
        label='Tipo de sociedad'
      >
        {Object
          .entries(SOCIETY_TYPE_LABELS)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map(([societyType, societyLabel]) => (
            <MenuItem
              key={societyType}
              value={societyType}
            >
              {societyLabel}
            </MenuItem>
          ))}
      </SelectField>
      <Field
        required
        name='identificationNumber'
        type='text'
        label='Rut empresa'
        component={TextField}
      />
      <Autocomplete
        options={SII_ACTIVITIES}
        value={findSiiActivity(values.siiActivityCode)}
        getOptionLabel={({ code, name }) => `${code} - ${name}`}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        onBlur={() => setFieldTouched('siiActivityCode', true)}
        onChange={(_event, newValue) => setFieldValue('siiActivityCode', newValue?.code || '')}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label='Actividad tributaria'
            required
          />
        )}
      />
    </Stack>
  )
}
