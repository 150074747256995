import * as React from 'react'

import { List, ListItem, ListItemText, Skeleton } from '@mui/material'

import { AppContainer, Currency } from '../components'

import type { MarketAsset } from '../queries'

type MarketStatisticsProps = {
  marketAsset?: MarketAsset
  lastPrice: number
}

export const MarketStatistics = ({
  marketAsset,
  lastPrice,
}: MarketStatisticsProps) => (
  <AppContainer
    md={6}
    sx={{ height: '100%' }}
  >
    <List
      aria-label='Estadísticas de Mercado'
      sx={{ p: 1 }}
    >
      <ListItem divider>
        <ListItemText
          primaryTypographyProps={{
            variant: 'h5',
          }}
          primary='Estadísticas de Mercado'
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Capitalización de mercado'
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(marketAsset) ? (
            (marketAsset.circulatingSupply) ? (
              <Currency
                currency='USD'
                value={lastPrice * marketAsset.circulatingSupply}
                digits={0}
              />
            ) : (
              'Sin información'
            )
          ) : (
            <Skeleton width={120} />
          )}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Suministro circulante'
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(marketAsset) ? (
            (marketAsset.circulatingSupply) ? (
              <Currency
                currency={marketAsset.symbol}
                value={marketAsset.circulatingSupply}
                digits={0}
              />
            ) : (
              'Sin información'
            )
          ) : (
            <Skeleton width={120} />
          )}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Suministro total'
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(marketAsset) ? (
            (marketAsset.totalSupply) ? (
              <Currency
                currency={marketAsset.symbol}
                value={marketAsset.totalSupply}
                digits={0}
              />
            ) : (
              'Sin información'
            )
          ) : (
            <Skeleton width={120} />
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary='Suministro máximo'
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(marketAsset) ? (
            (marketAsset.maxSupply) ? (
              <Currency
                currency={marketAsset.symbol}
                value={marketAsset.maxSupply}
                digits={0}
              />
            ) : (
              'Sin información'
            )
          ) : (
            <Skeleton width={120} />
          )}
        />
      </ListItem>
    </List>
  </AppContainer>
)
