import * as React from 'react'

import { Autocomplete, TextField } from '@mui/material'
import { useFormikContext } from 'formik'

import { AssetBadge, MarketAssetAdornment, OptionDisplay } from '../../components'

import type { FormValues, TotalAmounts } from './types'
import type { MarketAsset, SwapConfig } from '../../queries'
import type { AutocompleteRenderInputParams } from 'formik-mui'

export const allowedFrom = (
  marketAssets: MarketAsset[],
  swapConfig: SwapConfig,
  totalAmounts: TotalAmounts,
): MarketAsset[] => {
  const available = swapConfig.allowedPairs
    .flat()
    .filter((value, index, array) => array.indexOf(value) == index)

  return marketAssets
    .filter(({ symbol }) => available.includes(symbol))
    .filter(({ symbol }) => totalAmounts[symbol])
}

export const allowedTo = (
  marketAssets: MarketAsset[],
  swapConfig: SwapConfig,
  fromSymbol: string,
): MarketAsset[] => {
  const available = swapConfig.allowedPairs
    .filter((pair) => pair.includes(fromSymbol))
    .map((pair) => pair[0] === fromSymbol ? pair[1] : pair[0])

  return marketAssets
    .filter(({ symbol }) => available.includes(symbol))
}

const asOptionsArray = (marketAssets: MarketAsset[]) =>
  ['', ...marketAssets.map(({ symbol }) => symbol)]

const getOptionLabel = (marketAssets: MarketAsset[], symbol: string) =>
  marketAssets.find((marketAsset) => marketAsset.symbol === symbol)?.name || ''

type AssetPickerProps = {
  name: 'fromSymbol' | 'toSymbol'
  allowedAssets: MarketAsset[]
  disabled: boolean
  onChange?: () => void
}

export const AssetPicker = ({
  name,
  allowedAssets,
  disabled,
  onChange,
}: AssetPickerProps) => {
  const { errors, setFieldValue, touched, values } = useFormikContext<FormValues>()

  return (
    <Autocomplete
      disabled={disabled}
      options={asOptionsArray(allowedAssets)}
      getOptionLabel={(option: string) => getOptionLabel(allowedAssets, option)}
      value={values[name]}
      onChange={(_event, newValue) => {
        setFieldValue(name, newValue)
        onChange && onChange()
      }}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: string) => (
        (option !== '') && (
          <OptionDisplay {...props}>
            <AssetBadge
              symbol={option}
              height={20}
            />
            {getOptionLabel(allowedAssets, option)}
          </OptionDisplay>
        )
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          error={touched[name] && !!errors[name]}
          helperText={errors.fromSymbol}
          label='Selecciona una criptomoneda'
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            startAdornment: <MarketAssetAdornment symbol={values[name]} />,
          }}
        />
      )}
      disablePortal
    />
  )
}
