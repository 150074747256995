import * as React from 'react'

import { Stack } from '@mui/material'

import { PaymentStep } from './payment_step'
import { QuoteConfirmationStep } from './quote_confirmation_step'
import { QuoteVarsStep } from './quote_vars_step'
import { AssetBadge } from '../../components'

import type { CreatePurchaseQuoteVars, Payment, PurchaseConfig } from '../../queries'

type PurchaseFormProps = {
  purchaseConfig: PurchaseConfig
}

export const PurchaseForm = ({
  purchaseConfig,
}: PurchaseFormProps) => {
  const [step, setStep] = React.useState(1)
  const [createPurchaseQuoteVars, setCreatePurchaseQuoteVars] = React.useState<CreatePurchaseQuoteVars>()
  const [payment, setPayment] = React.useState<Payment>()

  const handleBack = async () => {
    setStep(Math.max(step - 1, 1))
  }

  return (
    <Stack
      pb={1}
      margin='auto'
      height='100%'
      width='100%'
      maxWidth='420px'
    >
      <AssetBadge
        animated
        symbol='USDC'
        height={100}
      />
      {(() => {
        switch (step) {
        case 1: return (
          <QuoteVarsStep
            purchaseConfig={purchaseConfig}
            createPurchaseQuoteVars={createPurchaseQuoteVars}
            handleNext={(vars: CreatePurchaseQuoteVars) => {
              setCreatePurchaseQuoteVars(vars)
              setStep(2)
            }}
          />
        )
        case 2: return (
          <QuoteConfirmationStep
            purchaseConfig={purchaseConfig}
            createPurchaseQuoteVars={createPurchaseQuoteVars}
            handleBack={handleBack}
            handleNext={(payment?: Payment) => {
              setPayment(payment)
              setStep(3)
            }}
          />
        )
        case 3: return (
          <PaymentStep
            purchaseConfig={purchaseConfig}
            createPurchaseQuoteVars={createPurchaseQuoteVars}
            payment={payment}
            handleRestart={() => setStep(1)}
          />
        )
        }
      })()}
    </Stack>
  )
}
